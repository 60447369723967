import { FC, useContext, useEffect, useState } from 'react'
import './index.scss';
import formData from './formData.json'

import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import {useCreateOneActionCardMutation, ActionCardCreateInput, AssigneeType,ActionCardType,ActionCardStatus, useGetUserEstateQuery, useCreateFolderMutation} from '../../../../types/graphql';
import { ApolloError } from '@apollo/client';
import { LayoutContext } from '../../../../providers/LayoutProvider';
import { Autocomplete, Container, TextField, Typography, Switch } from '@mui/material';
import { filterUndefinedValues, handleNestedValues } from '../../formHelpers'
import ActionButton from '../../../DesignSystem/atoms/ActionButton/ActionButton';
import { EstateContext } from '../../../../providers/EstateProvider';
import { formatEstateSelect } from '../../../../helpers/functions';
import { ActionContext } from '../../../../providers/ActionProvider';
import { formatActionName } from '../../../../helpers/functions';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import FolderSelector from '../../formikInputs/FolderSelector/FolderSelector';

  type CreateOneActionCardFormProps = {
    testId?: string
  }

const CreateOneActionCardForm: FC<CreateOneActionCardFormProps> = ({testId}) => {
  const {
    setDisplayModal,
    setModalContents,
  } = useContext(LayoutContext)
  const { 
    selectedEstateId,
    currentEstate,
  } = useContext(EstateContext)
  const {refetchActionList} = useContext(ActionContext)
  
  const [ createOneActionCardMutation ] = useCreateOneActionCardMutation();
  const [ createFolderMutation ] = useCreateFolderMutation();
  const [showCreateFolder, setShowCreateFolder] = useState(true);
  // const [ showCreateDownloadFolder, setShowCreateDownloadFolder ] = useState(false);
  const [ options, setOptions] = useState<any>({
    "estate": [
      {
        id: selectedEstateId,
        displayValue: formatEstateSelect(currentEstate?.getUserEstate)|| "Current Select Estate",
      },
    ],
    "component": [],
    "assignee": [
      {
        "submitValue": "CareTeam",
        "displayValue": "CareTeam",
      },
      {
        "submitValue": "Customer",
        "displayValue": "Customer",
      },
      {
        "submitValue": "OpsTeam",
        "displayValue": "OpsTeam",
      },
      {
        "submitValue": "ServiceAccount",
        "displayValue": "ServiceAccount",
      },
    ],
    "type": [
      {
        "submitValue": "DocumentSign",
        "displayValue": "Request Document Sign",
      },
      {
        "submitValue": "DocumentUpload",
        "displayValue": "Document Upload",
      },
      {
        "submitValue": "FormFields",
        "displayValue": "Form Field Info",
      },
      {
        "submitValue": "Manual",
        "displayValue": "Send Info",
      },
      {
        "submitValue": "ScheduleAppointment",
        "displayValue": "Schedule Appointment",
      },
    ],
    "status": [
      {
        "submitValue": "CANCELED",
        "displayValue": "Canceled",
      },
      {
        "submitValue": "COMPLETED",
        "displayValue": "Completed",
      },
      {
        "submitValue": "DRAFT",
        "displayValue": "Draft",
      },
      {
        "submitValue": "IN_PROGRESS",
        "displayValue": "InProgress",
      },
      {
        "submitValue": "NEW",
        "displayValue": "New",
      },
    ],
  })

  // estate query
  useGetUserEstateQuery({
    variables: {estateId: selectedEstateId},
    onCompleted: (data) => {
      const c = data.getUserEstate?.estateComponents.map( component => {
        return ({
          id: component.id, 
          displayValue: formatActionName(component),
        })
      })
      setOptions((options: any) => {
        const newOptions = {
          ...options,
          component: c,
        }
        return newOptions
      })
    },
    onError: (e) => {
      console.error('error in CreateOneActionCardForm', e);
    },
  })

  const handleSubmit = async (
    values: ActionCardCreateInput,
    { resetForm }: { resetForm: any },
  ) => {
    if (!selectedEstateId) return;
    const nestedValues = handleNestedValues(filterUndefinedValues(values), formData) as ActionCardCreateInput

    // Create the folder in S3 if showCreateFolder is true
    if (showCreateFolder && nestedValues.documentUploadUrl) {
      const createFolderResponse = await createFolderMutation({
        variables: {
          data: {
            estateId: selectedEstateId,
            folderName: nestedValues.documentUploadUrl, 
          }, 
        },
      });

      if (createFolderResponse.errors) {
        console.error('Error creating folder in S3');
        console.error(createFolderResponse.errors);
        toast('Error creating folder in S3');
        return;
      }
    }

    await createOneActionCardMutation({
      variables: {data: nestedValues},
      onCompleted: () => {
        refetchActionList();
        setDisplayModal(false);
        resetForm();
      }, 
      onError: (e: ApolloError) => {
        console.error("Failed createOneActionCardMutation call");
        console.error(e.message);
        resetForm();
        alert(e.message);
      },
    });
  }

  const initialValues = {
    estate: options.estate[0].id,
    component: undefined,
    assignee: AssigneeType.Customer,
    type: ActionCardType.Manual,
    status: ActionCardStatus.Draft,
    name: "",
    description: "",
    encryptedReply: false,
    locked: false,
    formFieldName: "",
    appointmentLocation: "",
    appointmentTime: "",
    calendarIcs: "",
    calendarLink: "",
    documentDownloadUrl: "",
    documentUploadUrl: "",
    documentSignUrl: "",
    mapUrl: "",
  };

  const validationSchema = Yup.object().shape({
    estate: Yup.string().required("estate is required"),
    component: Yup.string(),
    assignee: Yup.string(),
    type: Yup.string().required("type is required"),
    status: Yup.string(),
    name: Yup.string().required("name is required"),
    description: Yup.string(),
    encryptedReply: Yup.boolean(),
    locked: Yup.boolean(),
    formFieldName: Yup.string(),
    appointmentLocation: Yup.string(),
    appointmentTime: Yup.date(),
    calendarIcs: Yup.string(),
    calendarLink: Yup.string(),
    documentDownloadUrl: Yup.string(),
    documentUploadUrl: Yup.string()
      .matches(/^[^/]*$/, 'Slash (/) is not allowed in documentUploadUrl')
      .when('type', {
        is: ActionCardType.DocumentUpload,
        then: (schema) => schema.required('Document upload folder is required for this type of Action Card'),
      }),
    documentSignUrl: Yup.string(),
    mapUrl: Yup.string(), 
  })

  return (
    <div data-testid={testId} className='createOneActionCardFormContainer'>
      <Typography variant='h4' className='createOneActionCardFormHeader'>
          Create One Action Card Form
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors, touched, submitCount, setFieldValue, values,
        }) => (
          <Form noValidate>
            {/* <LinkModal
                title="Create New Folder"
                bodyText="You Are navigating to a new page to create a new folder. Please click the link below to continue.
                Your form has been saved as a draft and you can return to it after creating the folder."
                ariaLabel="Navigate to Document Vault to Create Folder"
                linkText="Create Folder"
                buttonVariant="solid"
                navigateTo="/documents"
                isOpen={showCreateFolderLinkModal}
                handleFormSubmit={() => {
                  handleSubmit(values)
                }}
              /> */}
            <Container className="formContainer">
              <Typography variant="h5">Relationships</Typography>
              <Container className="formSection">

                <Field 
                  type="hidden"
                  id="estate"
                  name="estate"
                  value={options.estate[0]?.id}
                />
                        
                <Field 
                  type="hidden"
                  id="assignee"
                  name="assignee"
                  value={options.assignee.find((option: any) => option.submitValue === initialValues.assignee)?.submitValue}
                />
        
                <div className={"inputWrapper componentFieldWrapper dropdownInputWrapper"} key={"component1"} data-testid={testId}>
                  <Autocomplete
                    options={options.component || []}
                    getOptionLabel={(option: {id: number, displayValue: string}) => option.displayValue}
                    renderInput={(params: any) => 
                      <Field 
                        {...params}
                        className={"componentInput fieldInput dropdownInput"}
                        required={false}
                        id={"component"}
                        label={"Component"}
                        name={"component"}
                        type={"text"}
                        autoComplete={"component"}
                        as={TextField}
                        error={submitCount > 0 && touched.component&& !!errors.component}                        
                      />
                    }
                    onChange={(_, value) => setFieldValue("component", value?.id)}
                  />
                  <ErrorMessage name={"component"} component="div" className="inputErrorMessage"/>
                </div>
              </Container>

              <hr />

              <Typography variant="h5">Required Information</Typography>
              <Container className="formSection">

                <div className={"inputWrapper typeFieldWrapper dropdownInputWrapper"} key={"type4"} data-testid={testId}>
                  <Autocomplete
                    options={options.type || []}
                    getOptionLabel={(option: {submitValue: string, displayValue: string}) => option.displayValue}
                    renderInput={(params: any) => 
                      <Field 
                        {...params}
                        className={"typeInput fieldInput dropdownInput"}
                        required={true}
                        id={"type"}
                        label={"Type"}
                        name={"type"}
                        type={"text"}
                        autoComplete={"type"}
                        as={TextField}
                        error={submitCount > 0 && touched.type&& !!errors.type}                        
                      />
                    }
                    defaultValue={options.type.find((option: any) => option.submitValue === initialValues.type)}
                    onChange={(_, value) =>{ 
                      console.log('value.submitValue', value?.submitValue);
                      setFieldValue("type", value?.submitValue)
                    }}
                  />
                  <ErrorMessage name={"type"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper statusFieldWrapper dropdownInputWrapper"} key={"status5"} data-testid={testId}>
                  <Autocomplete
                    options={options.status || []}
                    getOptionLabel={(option: {submitValue: string, displayValue: string}) => option.displayValue}
                    renderInput={(params: any) => 
                      <Field 
                        {...params}
                        className={"statusInput fieldInput dropdownInput"}
                        required={false}
                        id={"status"}
                        label={"Status"}
                        name={"status"}
                        type={"text"}
                        autoComplete={"status"}
                        as={TextField}
                        error={submitCount > 0 && touched.status&& !!errors.status}                        
                      />
                    }
                    defaultValue={options.status.find((option: any) => option.submitValue === initialValues.status)}
                    onChange={(_, value) => setFieldValue("status", value?.submitValue)}
                  />
                  <ErrorMessage name={"status"} component="div" className="inputErrorMessage"/>
                </div>
      
                <div className={"inputWrapper nameFieldWrapper textInputWrapper"} key={"name6"} data-testid={testId}>
                  <Field
                    className={"nameInput fieldInput textInput"}
                    required={true}
                    id={"name"}
                    label={"Name"}
                    name={"name"}
                    type={"text"}
                    autoComplete={"name"}
                    as={TextField}
                    error={submitCount > 0 && touched.name&& !!errors.name}
                  />
                  <ErrorMessage name={"name"} component="div" className="inputErrorMessage"/>
                </div>
        
                <div className={"inputWrapper descriptionFieldWrapper textInputWrapper"} key={"description7"} data-testid={testId}>
                  <label>Description</label>
                  <ReactQuill
                    className={`descriptionInput fieldInput textInput ${errors.description && touched.description ? 'error' : ''}`}
                    value={values.description || ""}
                    id={"description"}
                    onChange={(content) => setFieldValue("description", content)}
                  />
                  {submitCount > 0 && errors.description && touched.description && <div className="inputErrorMessage">{errors.description}</div>}
                </div>
              </Container>

              <hr />

              <Typography variant="h5">Additional Information</Typography>
              <Container className="formSection">
                  
                <div className={"inputWrapper lockedFieldWrapper booleanInputWrapper"} key={"locked0"} data-testid={testId}>
                  <Typography className="booleanLabel" variant="body1" align="left">Locked</Typography>
                  <Typography className="booleanNegative">No</Typography>
                  <Field 
                    className={"lockedInput fieldInput booleanInput"}
                    required={false}
                    id={"locked"}
                    name={"locked"}
                    type={"checkbox"} 
                    as={Switch} 
                    error={submitCount > 0 && touched.locked && !!errors.locked}
                  />
                  <Typography className="booleanPositive">Yes</Typography>
                  <ErrorMessage name={"locked"} component="div" className="inputErrorMessage"/>
                </div>

                { values.type === ActionCardType.FormFields && <div className={"inputWrapper encryptedReplyFieldWrapper booleanInputWrapper"} key={"encryptedReply8"} data-testid={testId}>
                  <Typography className="booleanLabel" variant="body1" align="left">Encrypted Reply</Typography>
                  <Typography className="booleanNegative">No</Typography>
                  <Field 
                    className={"encryptedReplyInput fieldInput booleanInput"}
                    required={false}
                    id={"encryptedReply"}
                    name={"encryptedReply"}
                    type={"checkbox"} 
                    as={Switch} 
                    error={submitCount > 0 && touched.encryptedReply&& !!errors.encryptedReply}
                  />
                  <Typography className="booleanPositive">Yes</Typography>
                  <ErrorMessage name={"encryptedReply"} component="div" className="inputErrorMessage"/>
                </div>}
          
                { values.type === ActionCardType.FormFields &&
                      <div className={"inputWrapper formFieldNameFieldWrapper textInputWrapper"} key={"formFieldName9"} data-testid={testId}>
                        <Field
                          className={"formFieldNameInput fieldInput textInput"}
                          required={false}
                          id={"formFieldName"}
                          label={"Form Field Name"}
                          name={"formFieldName"}
                          type={"text"}
                          autoComplete={"formFieldName"}
                          as={TextField}
                          error={submitCount > 0 && touched.formFieldName&& !!errors.formFieldName}
                        />
                        <ErrorMessage name={"formFieldName"} component="div" className="inputErrorMessage"/>
                      </div>}
          
                { values.type === ActionCardType.Manual &&
                      <div className={"inputWrapper appointmentLocationFieldWrapper textInputWrapper"} key={"appointmentLocation10"} data-testid={testId}>
                        <label>Appointment Details</label>
                        <ReactQuill
                          className={"appointmentLocationInput fieldInput textInput"}
                          id={"appointmentLocation"}
                          value={values.appointmentLocation || ""}
                          onChange={(content) => setFieldValue("appointmentLocation", content)}
                        />
                        {errors.appointmentLocation && touched.appointmentLocation && <div className="inputErrorMessage">{errors.appointmentLocation}</div>}
                      </div>
                }
          
                {/* { values.type === ActionCardType.Manual &&
                      <div className={"inputWrapper appointmentTimeFieldWrapper textInputWrapper"} key={"appointmentTime11"} data-testid={testId}>
                        <Field
                          className={"appointmentTimeInput fieldInput textInput"}
                          required={false}
                          id={"appointmentTime"}
                          label={"Appointment Time"}
                          name={"appointmentTime"}
                          type={"text"}
                          autoComplete={"appointmentTime"}
                          as={TextField}
                          error={submitCount > 0 && touched.appointmentTime&& !!errors.appointmentTime}
                        />
                        <ErrorMessage name={"appointmentTime"} component="div" className="inputErrorMessage"/>
                      </div>} */}
          
                {/* NOTE: These are commented as the functionality to create these links is not present yet */}
                {/* { values.type === ActionCardType.Manual &&
                      <div className={"inputWrapper calendarIcsFieldWrapper textInputWrapper"} key={"calendarIcs12"} data-testid={testId}>
                        <Field
                          className={"calendarIcsInput fieldInput textInput"}
                          required={false}
                          id={"calendarIcs"}
                          label={"Calendar Ics"}
                          name={"calendarIcs"}
                          type={"text"}
                          autoComplete={"calendarIcs"}
                          as={TextField}
                          error={submitCount > 0 && touched.calendarIcs&& !!errors.calendarIcs}
                        />
                        <ErrorMessage name={"calendarIcs"} component="div" className="inputErrorMessage"/>
                      </div>} */}
          
                { values.type === ActionCardType.ScheduleAppointment &&
                      <div className={"inputWrapper calendarLinkFieldWrapper textInputWrapper"} key={"calendarLink13"} data-testid={testId}>
                        <Field
                          className={"calendarLinkInput fieldInput textInput"}
                          required={false}
                          id={"calendarLink"}
                          label={"Calendar Link"}
                          name={"calendarLink"}
                          type={"text"}
                          autoComplete={"calendarLink"}
                          as={TextField}
                          error={submitCount > 0 && touched.calendarLink&& !!errors.calendarLink}
                        />
                        <ErrorMessage name={"calendarLink"} component="div" className="inputErrorMessage"/>
                      </div>}
          
                { values.type === ActionCardType.Manual &&
                      <div className={"inputWrapper documentDownloadUrlFieldWrapper textInputWrapper"} key={"documentDownloadUrl14"} data-testid={testId}>
                        <FolderSelector
                          fieldName="documentDownloadUrl"
                          labelText="Choose Folder to Download from"
                          initialValue={values.documentDownloadUrl}
                        />
                        <Typography variant='body1' className='downloadMessage'>If you have not created your folder save this form as a draft and create your folder in the Document Vault</Typography>
                      </div>}
          
                { values.type === ActionCardType.DocumentUpload &&
                  !showCreateFolder &&
                      <div className={"inputWrapper documentUploadUrlFieldWrapper textInputWrapper"} key={"documentUploadUrl15"} data-testid={testId}>
                        <FolderSelector
                          fieldName="documentUploadUrl"
                          labelText="Choose Folder to Upload to"
                          initialValue={values.documentUploadUrl}
                        />
                        <ActionButton 
                          ariaLabel={'Show Create Folder Field'}
                          variant="solid"
                          className="createFolderButton transparent"
                          handleClick={() => {
                            setShowCreateFolder(!showCreateFolder);
                          }}
                        >
                          + Create a new folder
                        </ActionButton>
                      </div>}
                  

                { values.type === ActionCardType.DocumentUpload && showCreateFolder &&
                  <div className={"inputWrapper documentUploadUrlFieldWrapper textInputWrapper"} key={"documentUploadUrl17"} data-testid={testId}>
                    <Field
                      className={"documentUploadUrlInput fieldInput textInput"}
                      required={false}
                      id={"documentUploadUrl"}
                      label={"Type Name of Folder to Create for Upload"}
                      name={"documentUploadUrl"}
                      type={"text"}
                      autoComplete={"documentUploadUrl"}
                      as={TextField}
                      error={submitCount > 0 && touched.documentUploadUrl&& !!errors.documentUploadUrl}
                    />
                    <ErrorMessage name={"documentUploadUrl"} component="div" className="inputErrorMessage"/>
                    <ActionButton 
                      ariaLabel={'Show Create Folder Field'}
                      variant="solid"
                      className="createFolderButton transparent"
                      handleClick={() => {
                        setShowCreateFolder(!showCreateFolder);
                      }}
                    >
                      Use Existing Folder
                    </ActionButton>
                  </div>}
          
                { values.type === ActionCardType.DocumentSign &&
                      <div className={"inputWrapper documentSignUrlFieldWrapper textInputWrapper"} key={"documentSignUrl16"} data-testid={testId}>
                        <Field
                          className={"documentSignUrlInput fieldInput textInput"}
                          required={false}
                          id={"documentSignUrl"}
                          label={"Document Sign Url"}
                          name={"documentSignUrl"}
                          type={"text"}
                          autoComplete={"documentSignUrl"}
                          as={TextField}
                          error={submitCount > 0 && touched.documentSignUrl&& !!errors.documentSignUrl}
                        />
                        <ErrorMessage name={"documentSignUrl"} component="div" className="inputErrorMessage"/>
                      </div>}
          
                { values.type === ActionCardType.Manual &&
                      <div className={"inputWrapper mapUrlFieldWrapper textInputWrapper"} key={"mapUrl17"} data-testid={testId}>
                        <Field
                          className={"mapUrlInput fieldInput textInput"}
                          required={false}
                          id={"mapUrl"}
                          label={"Map Url"}
                          name={"mapUrl"}
                          type={"text"}
                          autoComplete={"mapUrl"}
                          as={TextField}
                          error={submitCount > 0 && touched.mapUrl&& !!errors.mapUrl}
                        />
                        <ErrorMessage name={"mapUrl"} component="div" className="inputErrorMessage"/>
                      </div>}
              </Container>

            </Container>

            <Container className="buttonsContainer">
              <ActionButton
                ariaLabel="Submit form"
                variant="outline"
                className="submitFormButton"
                handleClick={() => {
                  setDisplayModal(false);
                  setModalContents(null);
                }}
              >
                        Cancel
              </ActionButton>

              <ActionButton
                type="submit"
                ariaLabel="Submit form"
                variant="solid"
                className="submitFormButton"
              >
                        Submit
              </ActionButton>
            </Container>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CreateOneActionCardForm