import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: { input: any; output: any; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar.This scalar is serialized to a string in ISO 8601 format and parsed from a string in ISO 8601 format. */
  DateTimeISO: { input: any; output: any; }
  /** GraphQL Scalar representing the Prisma.Decimal type, based on Decimal.js library. */
  Decimal: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
};

export type ActionCard = {
  __typename?: 'ActionCard';
  appointmentLocation?: Maybe<Scalars['String']['output']>;
  appointmentTime?: Maybe<Scalars['DateTimeISO']['output']>;
  assignee?: Maybe<AssigneeType>;
  calendarIcs?: Maybe<Scalars['String']['output']>;
  calendarLink?: Maybe<Scalars['String']['output']>;
  completedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  completedBy?: Maybe<Scalars['String']['output']>;
  component?: Maybe<Component>;
  componentId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  description?: Maybe<Scalars['String']['output']>;
  documentDownloadUrl?: Maybe<Scalars['String']['output']>;
  documentSignUrl?: Maybe<Scalars['String']['output']>;
  documentUploadUrl?: Maybe<Scalars['String']['output']>;
  encryptedReply: Scalars['Boolean']['output'];
  estate: Estate;
  estateDetail?: Maybe<EstateDetail>;
  estateDetailId?: Maybe<Scalars['String']['output']>;
  estateId: Scalars['String']['output'];
  followUpActionCard?: Maybe<Scalars['JSON']['output']>;
  formFieldName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  locked: Scalars['Boolean']['output'];
  mapUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  promoted: Scalars['Boolean']['output'];
  requiresFollowUp: Scalars['Boolean']['output'];
  status: ActionCardStatus;
  /** Retaining this for backwards compatibility with olders versions of the Native App, but we do not currently support this field so it will always be undefined */
  task?: Maybe<Task>;
  taskId?: Maybe<Scalars['String']['output']>;
  taskName?: Maybe<Scalars['String']['output']>;
  type: ActionCardType;
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type ActionCardCreateInput = {
  appointmentLocation?: InputMaybe<Scalars['String']['input']>;
  appointmentTime?: InputMaybe<Scalars['DateTimeISO']['input']>;
  assignee?: InputMaybe<AssigneeType>;
  calendarIcs?: InputMaybe<Scalars['String']['input']>;
  calendarLink?: InputMaybe<Scalars['String']['input']>;
  component?: InputMaybe<ComponentCreateNestedOneWithoutActionCardsInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  documentDownloadUrl?: InputMaybe<Scalars['String']['input']>;
  documentSignUrl?: InputMaybe<Scalars['String']['input']>;
  documentUploadUrl?: InputMaybe<Scalars['String']['input']>;
  encryptedReply?: InputMaybe<Scalars['Boolean']['input']>;
  estate: EstateCreateNestedOneWithoutActionCardsInput;
  followUpActionCard?: InputMaybe<Scalars['JSON']['input']>;
  formFieldName?: InputMaybe<Scalars['String']['input']>;
  locked?: InputMaybe<Scalars['Boolean']['input']>;
  mapUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  status?: InputMaybe<ActionCardStatus>;
  type: ActionCardType;
};

export type ActionCardOrderByWithRelationInput = {
  assignee?: InputMaybe<SortOrderInput>;
  completedAt?: InputMaybe<SortOrderInput>;
  completedBy?: InputMaybe<SortOrderInput>;
  componentId?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  estateDetailId?: InputMaybe<SortOrderInput>;
  estateId?: InputMaybe<SortOrder>;
  followUpActionCard?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  locked?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  requiresFollowUp?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ActionCardStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  InProgress = 'IN_PROGRESS',
  New = 'NEW'
}

export enum ActionCardType {
  ConnectBankAccount = 'ConnectBankAccount',
  DocumentDownload = 'DocumentDownload',
  DocumentSign = 'DocumentSign',
  DocumentUpload = 'DocumentUpload',
  FormFields = 'FormFields',
  Manual = 'Manual',
  ScheduleAppointment = 'ScheduleAppointment'
}

export type ActionCardUpdateInput = {
  appointmentLocation?: InputMaybe<Scalars['String']['input']>;
  appointmentTime?: InputMaybe<Scalars['DateTimeISO']['input']>;
  assignee?: InputMaybe<AssigneeType>;
  calendarIcs?: InputMaybe<Scalars['String']['input']>;
  calendarLink?: InputMaybe<Scalars['String']['input']>;
  component?: InputMaybe<ComponentUpdateOneWithoutActionCardsNestedInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  documentDownloadUrl?: InputMaybe<Scalars['String']['input']>;
  documentSignUrl?: InputMaybe<Scalars['String']['input']>;
  documentUploadUrl?: InputMaybe<Scalars['String']['input']>;
  encryptedReply?: InputMaybe<Scalars['Boolean']['input']>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutActionCardsNestedInput>;
  formFieldName?: InputMaybe<Scalars['String']['input']>;
  locked?: InputMaybe<Scalars['Boolean']['input']>;
  mapUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ActionCardStatus>;
  type?: InputMaybe<ActionCardType>;
};

export type ActionCardWhereInput = {
  AND?: InputMaybe<Array<ActionCardWhereInput>>;
  NOT?: InputMaybe<Array<ActionCardWhereInput>>;
  OR?: InputMaybe<Array<ActionCardWhereInput>>;
  assignee?: InputMaybe<EnumAssigneeTypeNullableFilter>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  completedBy?: InputMaybe<StringNullableFilter>;
  componentId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  estateDetailId?: InputMaybe<StringNullableFilter>;
  estateId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  locked?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumActionCardStatusFilter>;
  type?: InputMaybe<EnumActionCardTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ActionCardWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type AdminCreateUserWithEstateInput = {
  email: Scalars['String']['input'];
  estates?: InputMaybe<UsersOnEstatesCreateNestedManyWithoutUserInput>;
  externalId: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<UserType>;
  userRole?: InputMaybe<UserEstateRole>;
};

export type AlixFee = {
  __typename?: 'AlixFee';
  amount: Scalars['Decimal']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  estateId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  invoicedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type AlixFeeDisplay = {
  __typename?: 'AlixFeeDisplay';
  amount: Scalars['Decimal']['output'];
  status: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export enum AssigneeType {
  CareTeam = 'CareTeam',
  Customer = 'Customer',
  OpsTeam = 'OpsTeam',
  ServiceAccount = 'ServiceAccount'
}

export type Beneficiary = {
  __typename?: 'Beneficiary';
  componentId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  estateId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  relationship?: Maybe<Scalars['String']['output']>;
  taxId?: Maybe<Scalars['String']['output']>;
  type: BeneficiaryType;
  updatedAt: Scalars['DateTimeISO']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export type BeneficiaryDistributionInput = {
  beneficiaryId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  percentage: Scalars['Decimal']['input'];
};

export enum BeneficiaryType {
  AdultChild = 'AdultChild',
  Charity = 'Charity',
  Estate = 'Estate',
  Individual = 'Individual',
  MinorChild = 'MinorChild',
  OtherDependent = 'OtherDependent',
  SurvivingSpouse = 'SurvivingSpouse',
  Trust = 'Trust'
}

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Component = {
  __typename?: 'Component';
  accountHolder?: Maybe<Scalars['String']['output']>;
  accountNumber?: Maybe<Scalars['String']['output']>;
  actionCards: Array<ActionCard>;
  address?: Maybe<Scalars['String']['output']>;
  appraisedValue?: Maybe<Scalars['Decimal']['output']>;
  balance?: Maybe<Scalars['Decimal']['output']>;
  beneficiariesText?: Maybe<Scalars['String']['output']>;
  beneficiaryDesignated?: Maybe<Scalars['Boolean']['output']>;
  billingPeriod?: Maybe<Scalars['String']['output']>;
  charge?: Maybe<Scalars['Decimal']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  completedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  contactName?: Maybe<Scalars['String']['output']>;
  contactType?: Maybe<Scalars['String']['output']>;
  contents: Array<Content>;
  costBasis?: Maybe<Scalars['Decimal']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  createdById?: Maybe<Scalars['String']['output']>;
  createdByUser?: Maybe<User>;
  descriptor: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  estate: Estate;
  estateId: Scalars['String']['output'];
  estimatedValue?: Maybe<Scalars['Decimal']['output']>;
  finalValue?: Maybe<Scalars['Decimal']['output']>;
  icon: Scalars['String']['output'];
  id: Scalars['String']['output'];
  inTrust?: Maybe<Scalars['Boolean']['output']>;
  lastCheckDate?: Maybe<Scalars['DateTimeISO']['output']>;
  lastPaymentAmount?: Maybe<Scalars['Decimal']['output']>;
  lastPaymentDate?: Maybe<Scalars['DateTimeISO']['output']>;
  loanAmount?: Maybe<Scalars['Decimal']['output']>;
  mortgageBank?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  namedBeneficiaries: Array<Scalars['String']['output']>;
  notifications: Array<EstateNotification>;
  paidOff?: Maybe<Scalars['Boolean']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  registrationExpiresAt?: Maybe<Scalars['DateTimeISO']['output']>;
  seenByCustomer?: Maybe<Scalars['Boolean']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  status: ComponentStatus;
  subType: ComponentSubType;
  subTypeDisplayName: Scalars['String']['output'];
  type: ComponentType;
  typeDisplayName: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  zip?: Maybe<Scalars['String']['output']>;
};


export type ComponentContentsArgs = {
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput>>;
  where?: InputMaybe<ContentWhereInput>;
};

export type ComponentBreakDown = {
  __typename?: 'ComponentBreakDown';
  count: Scalars['BigInt']['output'];
  percentage: Scalars['Decimal']['output'];
  subTotalByType: Scalars['Decimal']['output'];
  subType: Scalars['String']['output'];
  sum: Scalars['Decimal']['output'];
  total: Scalars['Decimal']['output'];
  type: Scalars['String']['output'];
};

export type ComponentCreateInput = {
  accountHolder?: InputMaybe<Scalars['String']['input']>;
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  appraisedValue?: InputMaybe<Scalars['Decimal']['input']>;
  balance?: InputMaybe<Scalars['Decimal']['input']>;
  billingPeriod?: InputMaybe<Scalars['String']['input']>;
  charge?: InputMaybe<Scalars['Decimal']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactType?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  descriptor: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  estate: EstateCreateNestedOneWithoutComponentsInput;
  estimatedValue?: InputMaybe<Scalars['Decimal']['input']>;
  finalValue?: InputMaybe<Scalars['Decimal']['input']>;
  inTrust?: InputMaybe<Scalars['Boolean']['input']>;
  lastCheckDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lastPaymentAmount?: InputMaybe<Scalars['Decimal']['input']>;
  lastPaymentDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  loanAmount?: InputMaybe<Scalars['Decimal']['input']>;
  mortgageBank?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  paidOff?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  registrationExpiresAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ComponentStatus>;
  subType: ComponentSubType;
  type: ComponentType;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentCreateNestedOneWithoutActionCardsInput = {
  connect?: InputMaybe<ComponentWhereUniqueInput>;
};

export type ComponentCreateNestedOneWithoutContentsInput = {
  connect?: InputMaybe<ComponentWhereUniqueInput>;
};

export type ComponentCreateWithoutEstateInput = {
  accountHolder?: InputMaybe<Scalars['String']['input']>;
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  appraisedValue?: InputMaybe<Scalars['Decimal']['input']>;
  balance?: InputMaybe<Scalars['Decimal']['input']>;
  beneficiariesText?: InputMaybe<Scalars['String']['input']>;
  beneficiaryDesignated?: InputMaybe<Scalars['Boolean']['input']>;
  billingPeriod?: InputMaybe<Scalars['String']['input']>;
  charge?: InputMaybe<Scalars['Decimal']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  completedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactType?: InputMaybe<Scalars['String']['input']>;
  costBasis?: InputMaybe<Scalars['Decimal']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  createdByUser?: InputMaybe<UserCreateNestedOneWithoutComponentsInput>;
  descriptor: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  estimatedValue?: InputMaybe<Scalars['Decimal']['input']>;
  finalValue?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inTrust?: InputMaybe<Scalars['Boolean']['input']>;
  lastCheckDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lastPaymentAmount?: InputMaybe<Scalars['Decimal']['input']>;
  lastPaymentDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  loanAmount?: InputMaybe<Scalars['Decimal']['input']>;
  mortgageBank?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  namedBeneficiaries?: InputMaybe<Array<Scalars['String']['input']>>;
  paidOff?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  registrationExpiresAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ComponentStatus>;
  subType: ComponentSubType;
  type: ComponentType;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export enum ComponentStatus {
  Blocked = 'Blocked',
  Completed = 'Completed',
  InProgress = 'InProgress',
  Pending = 'Pending'
}

export enum ComponentSubType {
  BankAccount = 'BankAccount',
  BillToCancel = 'BillToCancel',
  Debt = 'Debt',
  Discovery = 'Discovery',
  Distribution = 'Distribution',
  Investment = 'Investment',
  LifeInsurance = 'LifeInsurance',
  PersonalProperty = 'PersonalProperty',
  Probate = 'Probate',
  RealEstate = 'RealEstate',
  Retirement = 'Retirement',
  Tax = 'Tax',
  UnclaimedProperty = 'UnclaimedProperty',
  Vehicle = 'Vehicle'
}

export type ComponentSubTypeItem = {
  __typename?: 'ComponentSubTypeItem';
  displayName: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  type: ComponentSubType;
};

export enum ComponentType {
  Asset = 'Asset',
  Debt = 'Debt',
  Obligation = 'Obligation',
  Process = 'Process'
}

export type ComponentTypeItem = {
  __typename?: 'ComponentTypeItem';
  componentSubTypes: Array<ComponentSubTypeItem>;
  displayName: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  type: ComponentType;
};

export type ComponentUpdateInput = {
  accountHolder?: InputMaybe<Scalars['String']['input']>;
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  appraisedValue?: InputMaybe<Scalars['Decimal']['input']>;
  balance?: InputMaybe<Scalars['Decimal']['input']>;
  billingPeriod?: InputMaybe<Scalars['String']['input']>;
  charge?: InputMaybe<Scalars['Decimal']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactType?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  descriptor: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  estimatedValue?: InputMaybe<Scalars['Decimal']['input']>;
  finalValue?: InputMaybe<Scalars['Decimal']['input']>;
  inTrust?: InputMaybe<Scalars['Boolean']['input']>;
  lastCheckDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lastPaymentAmount?: InputMaybe<Scalars['Decimal']['input']>;
  lastPaymentDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  loanAmount?: InputMaybe<Scalars['Decimal']['input']>;
  mortgageBank?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  paidOff?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  registrationExpiresAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ComponentStatus>;
  subType: ComponentSubType;
  type: ComponentType;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentUpdateManyWithoutEstateNestedInput = {
  create?: InputMaybe<Array<ComponentCreateWithoutEstateInput>>;
  update?: InputMaybe<Array<ComponentUpdateWithWhereUniqueWithoutEstateInput>>;
};

export type ComponentUpdateOneWithoutActionCardsNestedInput = {
  connect?: InputMaybe<ComponentWhereUniqueInput>;
};

export type ComponentUpdateOneWithoutContentsNestedInput = {
  connect?: InputMaybe<ComponentWhereUniqueInput>;
};

export type ComponentUpdateWithWhereUniqueWithoutEstateInput = {
  data: ComponentUpdateWithoutEstateInput;
  where: ComponentWhereUniqueInput;
};

export type ComponentUpdateWithoutEstateInput = {
  accountHolder?: InputMaybe<Scalars['String']['input']>;
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  appraisedValue?: InputMaybe<Scalars['Decimal']['input']>;
  balance?: InputMaybe<Scalars['Decimal']['input']>;
  beneficiariesText?: InputMaybe<Scalars['String']['input']>;
  beneficiaryDesignated?: InputMaybe<Scalars['Boolean']['input']>;
  billingPeriod?: InputMaybe<Scalars['String']['input']>;
  charge?: InputMaybe<Scalars['Decimal']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  completedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactType?: InputMaybe<Scalars['String']['input']>;
  costBasis?: InputMaybe<Scalars['Decimal']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  descriptor?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  estimatedValue?: InputMaybe<Scalars['Decimal']['input']>;
  finalValue?: InputMaybe<Scalars['Decimal']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  inTrust?: InputMaybe<Scalars['Boolean']['input']>;
  lastCheckDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lastPaymentAmount?: InputMaybe<Scalars['Decimal']['input']>;
  lastPaymentDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  loanAmount?: InputMaybe<Scalars['Decimal']['input']>;
  mortgageBank?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  namedBeneficiaries?: InputMaybe<Array<Scalars['String']['input']>>;
  paidOff?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  registrationExpiresAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ComponentStatus>;
  subType?: InputMaybe<ComponentSubType>;
  type?: InputMaybe<ComponentType>;
  updatedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Content = {
  __typename?: 'Content';
  alixGotIt: Scalars['Boolean']['output'];
  body: Scalars['String']['output'];
  completeBy: Scalars['DateTimeISO']['output'];
  completeByType: ContentCompleteByType;
  completedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  completedBy?: Maybe<Scalars['String']['output']>;
  component?: Maybe<Component>;
  componentId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  displayDate: Scalars['String']['output'];
  header: Scalars['String']['output'];
  id: Scalars['String']['output'];
  notifications: Array<EstateNotification>;
  order: Scalars['Int']['output'];
  status: ContentStatus;
  type?: Maybe<ContentType>;
  updatedAt: Scalars['DateTimeISO']['output'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']['output']>;
};

export enum ContentCompleteByType {
  Exact = 'EXACT',
  Fuzzy = 'FUZZY',
  Month = 'MONTH'
}

export type ContentCreateInput = {
  body: Scalars['String']['input'];
  completeBy: Scalars['DateTimeISO']['input'];
  completeByType?: InputMaybe<ContentCompleteByType>;
  completedBy?: InputMaybe<Scalars['String']['input']>;
  component?: InputMaybe<ComponentCreateNestedOneWithoutContentsInput>;
  header: Scalars['String']['input'];
  status?: InputMaybe<ContentStatus>;
  type?: InputMaybe<ContentType>;
  user?: InputMaybe<UserCreateNestedOneWithoutContentsInput>;
};

export type ContentOrderByWithRelationInput = {
  completeBy?: InputMaybe<SortOrder>;
  completeByType?: InputMaybe<SortOrder>;
  completedAt?: InputMaybe<SortOrderInput>;
  completedBy?: InputMaybe<SortOrderInput>;
  componentId?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrderInput>;
};

export enum ContentStatus {
  Completed = 'COMPLETED',
  InProcess = 'IN_PROCESS'
}

export enum ContentType {
  KeyDate = 'KEY_DATE',
  RecentActivity = 'RECENT_ACTIVITY'
}

export type ContentUpdateInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  completeBy?: InputMaybe<Scalars['DateTimeISO']['input']>;
  completeByType?: InputMaybe<ContentCompleteByType>;
  completedAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  completedBy?: InputMaybe<Scalars['String']['input']>;
  component?: InputMaybe<ComponentUpdateOneWithoutContentsNestedInput>;
  header?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ContentStatus>;
  type?: InputMaybe<ContentType>;
  user?: InputMaybe<UserUpdateOneWithoutContentsNestedInput>;
};

export type ContentWhereInput = {
  AND?: InputMaybe<Array<ContentWhereInput>>;
  NOT?: InputMaybe<Array<ContentWhereInput>>;
  OR?: InputMaybe<Array<ContentWhereInput>>;
  alixGotIt?: InputMaybe<BoolFilter>;
  body?: InputMaybe<StringFilter>;
  completeBy?: InputMaybe<DateTimeFilter>;
  completeByType?: InputMaybe<EnumContentCompleteByTypeFilter>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  completedBy?: InputMaybe<StringNullableFilter>;
  componentId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  header?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumContentStatusFilter>;
  type?: InputMaybe<EnumContentTypeNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type ContentWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CreateExpenseInput = {
  amount: Scalars['Decimal']['input'];
  componentId?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['DateTimeISO']['input'];
  estateId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateFolderInput = {
  estateId: Scalars['String']['input'];
  folderName: Scalars['String']['input'];
};

export type CreateNotificationByGroupInput = {
  enablePush?: InputMaybe<Scalars['Boolean']['input']>;
  estateId: Scalars['String']['input'];
  group: NotificationGroup;
  message: Scalars['String']['input'];
  relatedElementType?: InputMaybe<RelatedElementType>;
  routeElements?: InputMaybe<Array<RouteElementInput>>;
  title: Scalars['String']['input'];
};

export type CreateUserInput = {
  email: Scalars['String']['input'];
  estates?: InputMaybe<UsersOnEstatesCreateNestedManyWithoutUserInput>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  messageAction?: InputMaybe<MessageAction>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  type?: InputMaybe<UserType>;
  userRole?: InputMaybe<UserEstateRole>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type Deceased = {
  __typename?: 'Deceased';
  address?: Maybe<Scalars['String']['output']>;
  citizenship?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  dateOfBirth?: Maybe<Scalars['DateTimeISO']['output']>;
  dateOfDeath?: Maybe<Scalars['DateTimeISO']['output']>;
  estate?: Maybe<Estate>;
  estateId: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  maritalStatus?: Maybe<Scalars['String']['output']>;
  nameOfHospital?: Maybe<Scalars['String']['output']>;
  podAddress?: Maybe<Scalars['String']['output']>;
  podCity?: Maybe<Scalars['String']['output']>;
  podCountry?: Maybe<Scalars['String']['output']>;
  podCounty?: Maybe<Scalars['String']['output']>;
  podState?: Maybe<Scalars['String']['output']>;
  podZip?: Maybe<Scalars['String']['output']>;
  referringPartner?: Maybe<Scalars['String']['output']>;
  ssnLast4?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTimeISO']['output'];
  veteranStatus?: Maybe<Scalars['Boolean']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type DeceasedCreateInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  citizenship?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['DateTimeISO']['input']>;
  dateOfDeath?: InputMaybe<Scalars['DateTimeISO']['input']>;
  estate: EstateCreateNestedOneWithoutDeceasedInput;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  maritalStatus?: InputMaybe<Scalars['String']['input']>;
  nameOfHospital?: InputMaybe<Scalars['String']['input']>;
  podAddress?: InputMaybe<Scalars['String']['input']>;
  podCity?: InputMaybe<Scalars['String']['input']>;
  podCountry?: InputMaybe<Scalars['String']['input']>;
  podCounty?: InputMaybe<Scalars['String']['input']>;
  podState?: InputMaybe<Scalars['String']['input']>;
  podZip?: InputMaybe<Scalars['String']['input']>;
  referringPartner?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  veteranStatus?: InputMaybe<Scalars['Boolean']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type DeceasedCreateNestedOneWithoutEstateInput = {
  create?: InputMaybe<DeceasedCreateWithoutEstateInput>;
};

export type DeceasedCreateWithoutEstateInput = {
  dateOfBirth?: InputMaybe<Scalars['DateTimeISO']['input']>;
  dateOfDeath?: InputMaybe<Scalars['DateTimeISO']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
};

export type DeceasedUpdateInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  citizenship?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['DateTimeISO']['input']>;
  dateOfDeath?: InputMaybe<Scalars['DateTimeISO']['input']>;
  estate?: InputMaybe<EstateUpdateOneRequiredWithoutDeceasedNestedInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  maritalStatus?: InputMaybe<Scalars['String']['input']>;
  nameOfHospital?: InputMaybe<Scalars['String']['input']>;
  podAddress?: InputMaybe<Scalars['String']['input']>;
  podCity?: InputMaybe<Scalars['String']['input']>;
  podCountry?: InputMaybe<Scalars['String']['input']>;
  podCounty?: InputMaybe<Scalars['String']['input']>;
  podState?: InputMaybe<Scalars['String']['input']>;
  podZip?: InputMaybe<Scalars['String']['input']>;
  referringPartner?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  veteranStatus?: InputMaybe<Scalars['Boolean']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type DeceasedUpdateOneWithoutEstateNestedInput = {
  connect?: InputMaybe<DeceasedWhereUniqueInput>;
  create?: InputMaybe<DeceasedCreateWithoutEstateInput>;
};

export type DeceasedWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteUserNotificationsInput = {
  estateId: Scalars['String']['input'];
  estateNotificationId: Scalars['String']['input'];
};

export type DeleteUserNotificationsResult = {
  __typename?: 'DeleteUserNotificationsResult';
  estateId: Scalars['String']['output'];
  estateNotificationId: Scalars['String']['output'];
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type DeviceRegistration = {
  __typename?: 'DeviceRegistration';
  createdAt: Scalars['DateTimeISO']['output'];
  deviceToken: Scalars['String']['output'];
  endpoint?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  userId: Scalars['String']['output'];
};

export type Eula = {
  __typename?: 'EULA';
  content?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  pdfLink?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTimeISO']['output'];
  version: Scalars['String']['output'];
};

export type EulaAcceptanceHistory = {
  __typename?: 'EULAAcceptanceHistory';
  EULAacceptedAt: Scalars['DateTimeISO']['output'];
  EULAacceptedIp: Scalars['String']['output'];
  acceptedEULAVersion: Scalars['String']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  eulaId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  userId: Scalars['String']['output'];
};

export type EnumActionCardStatusFilter = {
  equals?: InputMaybe<ActionCardStatus>;
  in?: InputMaybe<Array<ActionCardStatus>>;
  notIn?: InputMaybe<Array<ActionCardStatus>>;
};

export type EnumActionCardTypeFilter = {
  equals?: InputMaybe<ActionCardType>;
  in?: InputMaybe<Array<ActionCardType>>;
  notIn?: InputMaybe<Array<ActionCardType>>;
};

export type EnumAssigneeTypeNullableFilter = {
  equals?: InputMaybe<AssigneeType>;
  in?: InputMaybe<Array<AssigneeType>>;
  notIn?: InputMaybe<Array<AssigneeType>>;
};

export type EnumContentCompleteByTypeFilter = {
  equals?: InputMaybe<ContentCompleteByType>;
  in?: InputMaybe<Array<ContentCompleteByType>>;
  notIn?: InputMaybe<Array<ContentCompleteByType>>;
};

export type EnumContentStatusFilter = {
  equals?: InputMaybe<ContentStatus>;
  in?: InputMaybe<Array<ContentStatus>>;
  notIn?: InputMaybe<Array<ContentStatus>>;
};

export type EnumContentTypeNullableFilter = {
  equals?: InputMaybe<ContentType>;
  in?: InputMaybe<Array<ContentType>>;
  notIn?: InputMaybe<Array<ContentType>>;
};

export type EnumUserEstateRoleNullableFilter = {
  equals?: InputMaybe<UserEstateRole>;
  in?: InputMaybe<Array<UserEstateRole>>;
  notIn?: InputMaybe<Array<UserEstateRole>>;
};

export type EnumUserTypeFilter = {
  equals?: InputMaybe<UserType>;
  in?: InputMaybe<Array<UserType>>;
  notIn?: InputMaybe<Array<UserType>>;
};

export type Estate = {
  __typename?: 'Estate';
  actionCards: Array<ActionCard>;
  alixFee?: Maybe<AlixFee>;
  beneficiaries: Array<Beneficiary>;
  channelPartner?: Maybe<Scalars['String']['output']>;
  /** Get the component breakdown for the estate. Only assets and debts are included in the breakdown. */
  componentBreakDown?: Maybe<Array<ComponentBreakDown>>;
  components: Array<Component>;
  contents: Array<Content>;
  createdAt: Scalars['DateTimeISO']['output'];
  currentWorkFLowStep: Scalars['Int']['output'];
  deceased?: Maybe<Deceased>;
  deleted: Scalars['Boolean']['output'];
  details: Array<EstateDetail>;
  estateComponents: Array<Component>;
  /** @deprecated Defaults to true for backwards compatibility with older client versions. */
  estateReady: Scalars['Boolean']['output'];
  hasProbate?: Maybe<Scalars['Boolean']['output']>;
  hasTrust?: Maybe<Scalars['Boolean']['output']>;
  hasWill?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  keyDates: Array<Content>;
  notifications: Array<EstateNotification>;
  /** @deprecated Defaults to false. No longer used. */
  planReady: Scalars['Boolean']['output'];
  processInstanceId?: Maybe<Scalars['String']['output']>;
  recentActivity: Array<Content>;
  survivingSpouse?: Maybe<Scalars['Boolean']['output']>;
  taxId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTimeISO']['output'];
  users: Array<UsersOnEstates>;
};


export type EstateActionCardsArgs = {
  orderBy?: InputMaybe<Array<ActionCardOrderByWithRelationInput>>;
  where?: InputMaybe<ActionCardWhereInput>;
};


export type EstateUsersArgs = {
  orderBy?: InputMaybe<Array<UsersOnEstatesOrderByWithRelationInput>>;
  where?: InputMaybe<UsersOnEstatesWhereInput>;
};

export type EstateCreateInput = {
  channelPartner?: InputMaybe<Scalars['String']['input']>;
  deceased?: InputMaybe<DeceasedCreateNestedOneWithoutEstateInput>;
  hasTrust?: InputMaybe<Scalars['Boolean']['input']>;
  hasWill?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EstateCreateNestedOneWithoutActionCardsInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
};

export type EstateCreateNestedOneWithoutComponentsInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
};

export type EstateCreateNestedOneWithoutDeceasedInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
};

export type EstateCreateNestedOneWithoutUsersInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
  create?: InputMaybe<EstateCreateWithoutUsersInput>;
};

export type EstateCreateWithoutUsersInput = {
  deceased?: InputMaybe<DeceasedCreateNestedOneWithoutEstateInput>;
  hasTrust?: InputMaybe<Scalars['Boolean']['input']>;
  hasWill?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EstateDetail = {
  __typename?: 'EstateDetail';
  createdAt: Scalars['DateTimeISO']['output'];
  decryptedValue?: Maybe<Scalars['JSON']['output']>;
  encrypted: Scalars['Boolean']['output'];
  estateId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  value: Scalars['JSON']['output'];
};

export type EstateDistributionBreakdown = {
  __typename?: 'EstateDistributionBreakdown';
  alixFee: AlixFeeDisplay;
  debts: Scalars['Decimal']['output'];
  distributionInfo?: Maybe<Info>;
  estimatedDistribution: Scalars['Decimal']['output'];
  feeCalculationInfo?: Maybe<Info>;
  totalValue: Scalars['Decimal']['output'];
};

export type EstateDocument = {
  __typename?: 'EstateDocument';
  contentType?: Maybe<Scalars['String']['output']>;
  deleteMarkerId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDir: Scalars['Boolean']['output'];
  modDate: Scalars['DateTimeISO']['output'];
  name: Scalars['String']['output'];
  signedUrl?: Maybe<Scalars['String']['output']>;
  size: Scalars['Float']['output'];
  uploadedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type EstateNotification = {
  __typename?: 'EstateNotification';
  actionCard: ActionCard;
  actionCardId?: Maybe<Scalars['String']['output']>;
  component?: Maybe<Component>;
  componentId?: Maybe<Scalars['String']['output']>;
  contentId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  estate?: Maybe<Estate>;
  estateId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  message: Scalars['String']['output'];
  relatedElementType?: Maybe<RelatedElementType>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  userNotifications: Array<UserNotification>;
};

export type EstateUpdateInput = {
  components?: InputMaybe<ComponentUpdateManyWithoutEstateNestedInput>;
  deceased?: InputMaybe<DeceasedUpdateOneWithoutEstateNestedInput>;
  users?: InputMaybe<UsersOnEstatesUpdateManyWithoutEstateNestedInput>;
};

export type EstateUpdateOneRequiredWithoutActionCardsNestedInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
};

export type EstateUpdateOneRequiredWithoutDeceasedNestedInput = {
  connect?: InputMaybe<EstateWhereUniqueInput>;
};

export type EstateWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Expense = {
  __typename?: 'Expense';
  amount: Scalars['Decimal']['output'];
  componentId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  date: Scalars['DateTimeISO']['output'];
  estateId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  paid: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export type ExpenseWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type FormTemplate = {
  __typename?: 'FormTemplate';
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type FormTemplateLink = {
  __typename?: 'FormTemplateLink';
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type GetLinkTokenResponse = {
  __typename?: 'GetLinkTokenResponse';
  linkToken: Scalars['String']['output'];
};

export type Info = {
  __typename?: 'Info';
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Set to SUPPRESS to suppress sending the message. Leave it blank if you want an invitation sent to the user's email. */
export enum MessageAction {
  Suppress = 'SUPPRESS'
}

export type MoveDocumentsInput = {
  destinationFolder: Scalars['String']['input'];
  documentIds: Array<Scalars['String']['input']>;
  estateId: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Accept the current EULA version */
  acceptEULA: User;
  /** Create a new estate by Admin. It also starts a new process instance in Camunda. */
  adminCreateEstate: Estate;
  /** Create a process instance in Camunde for a given estateId by Admin */
  adminCreateProcessInstance: Estate;
  /** Create a new user with an estate by Admin */
  adminCreateUserWithEstate?: Maybe<User>;
  /** Extract text from Bank Statement and run OpenAI chat query */
  analyzeBankStatement: Scalars['String']['output'];
  /** Associate a user with an estate. */
  associateUserWithEstate?: Maybe<User>;
  /** Capture the login of the current user. Admins are not captured. */
  captureLogin: Scalars['Boolean']['output'];
  /** Capture the login failure. */
  captureLoginFailure: Scalars['Boolean']['output'];
  /** Mark a component as seen by the customer. This mutation ensures that the component belongs to the logged in user's estate. */
  componentSeen?: Maybe<Component>;
  createDistribution: Scalars['Boolean']['output'];
  /** Get a signed url for document to download. */
  createDownloadUrl: Scalars['String']['output'];
  /** Create an expense - by customer */
  createExpense?: Maybe<Expense>;
  /** create a new folder under the estate. Admins mutation */
  createFolder: Scalars['Boolean']['output'];
  /** Call this mutation to get a Plaid link token */
  createLinkToken: GetLinkTokenResponse;
  /** ADMIN: Create new notification */
  createNotificationByGroup?: Maybe<EstateNotification>;
  createOneActionCard: ActionCard;
  createOneComponent: Component;
  createOneContent: Content;
  createOneDeceased: Deceased;
  /** Get a signed url for document upload. Admins must provide an estateId */
  createUploadUrl: Scalars['String']['output'];
  /** ADMIN Create a new user in Alix and also in cognito. */
  createUserCompletely: User;
  /** ADMIN Create a new user and an estate in Alix and also in cognito. It also creates a new process instance in Camunda */
  createUserCompletelyWithEstate: User;
  /** Create a new user with an estate. This mutation needs to be called after the user has been created in Cognito. */
  createUserWithEstate?: Maybe<User>;
  /** Deletes a file. */
  deleteDocument: Scalars['Boolean']['output'];
  /** SUPER_ADMIN mutations to soft delete an estate */
  deleteEstate: Scalars['Boolean']['output'];
  /** delete an expense */
  deleteExpense?: Maybe<Expense>;
  deleteOneActionCard?: Maybe<ActionCard>;
  deleteOneComponent?: Maybe<Component>;
  deleteOneContent?: Maybe<Content>;
  /** ADMIN: Delete an Estate Notification and all related User Notifications */
  deleteUserNotifications: DeleteUserNotificationsResult;
  /**
   * Call this mutation to link an account. Pass the public token recieved from Plaid
   *     and the component id as arguments.
   */
  linkAccount: Component;
  /** Update the AlixFee record to mark it as invoiced */
  markAlixFeeAsInvoiced?: Maybe<AlixFee>;
  /** Marks a userNotification as seen by setting a timestamp on seenAt. Admins must provide a userId. */
  markNotificationsSeen: Scalars['Boolean']['output'];
  /** Move file(s) for source to destination. Admins mutation */
  moveDocuments: Scalars['Boolean']['output'];
  prefillFormTemplate: FormTemplateLink;
  registerDevice: DeviceRegistration;
  /** Rename a document */
  renameDocument: Scalars['Boolean']['output'];
  /** Resend the invitation email to a user. */
  resendInvitation: Scalars['Boolean']['output'];
  /** Reset an expired Cognito password for a user by Admin */
  resetExpiredCognitoPassword: User;
  /** Restores document by removing delete marker. */
  restoreDocument: Scalars['Boolean']['output'];
  /** Scrape missing money */
  scrapeMissingMoney: Scalars['String']['output'];
  /** Create or update the amount for the AlixFee record for the estate */
  setAlixFeeAmount?: Maybe<AlixFee>;
  /** Sign up a new user and create an estate. No authentication is required. */
  signUpUser: User;
  /** Call this mutation when the customer takes action on an action card. */
  startActionCard: ActionCard;
  /** Textract Analysis and Form Data Extraction */
  textractAnalysis: Scalars['String']['output'];
  /** Track a click on an element */
  trackClick: Scalars['Boolean']['output'];
  /** Track a page view. */
  trackPageView: Scalars['Boolean']['output'];
  /** Track a scroll event */
  trackScroll: Scalars['Boolean']['output'];
  /** Track a section opened event */
  trackSectionOpened: Scalars['Boolean']['output'];
  /** Update the logged in user. */
  updateCurrentUser?: Maybe<User>;
  /** Update the ssn of the logged in user. */
  updateCurrentUserSsn?: Maybe<User>;
  /** Update the ssn of the deceased. Must be an admin to use this mutation. */
  updateDeceasedSsn?: Maybe<Deceased>;
  /** update an expense */
  updateExpense?: Maybe<Expense>;
  updateOneActionCard?: Maybe<ActionCard>;
  updateOneComponent?: Maybe<Component>;
  updateOneContent?: Maybe<Content>;
  updateOneDeceased?: Maybe<Deceased>;
  updateOneEstate?: Maybe<Estate>;
  updateOneUser?: Maybe<User>;
  /** Update the estate for the logged in user. If the user has multiple estates, an estateId can be provided. */
  updateUserEstate?: Maybe<Estate>;
  /** Update the ssn of the estate's deceased. */
  updateUserEstateDeceasedSsn?: Maybe<Deceased>;
  /** ADMIN Update IDP attributes of a user. */
  updateUserIDPAttributes: User;
  /** Update the ssn of a user. Must be an admin. */
  updateUserSsn?: Maybe<User>;
};


export type MutationAdminCreateEstateArgs = {
  data: EstateCreateInput;
};


export type MutationAdminCreateProcessInstanceArgs = {
  estateId: Scalars['String']['input'];
};


export type MutationAdminCreateUserWithEstateArgs = {
  data: AdminCreateUserWithEstateInput;
};


export type MutationAnalyzeBankStatementArgs = {
  filePaths: Array<Scalars['String']['input']>;
};


export type MutationAssociateUserWithEstateArgs = {
  estateId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
  userRole: UserEstateRole;
};


export type MutationCaptureLoginFailureArgs = {
  email: Scalars['String']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};


export type MutationComponentSeenArgs = {
  componentId: Scalars['String']['input'];
  estateId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateDistributionArgs = {
  componentId: Scalars['String']['input'];
  data: Array<BeneficiaryDistributionInput>;
};


export type MutationCreateDownloadUrlArgs = {
  estateId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};


export type MutationCreateExpenseArgs = {
  data: CreateExpenseInput;
};


export type MutationCreateFolderArgs = {
  data: CreateFolderInput;
};


export type MutationCreateNotificationByGroupArgs = {
  data: CreateNotificationByGroupInput;
};


export type MutationCreateOneActionCardArgs = {
  data: ActionCardCreateInput;
};


export type MutationCreateOneComponentArgs = {
  data: ComponentCreateInput;
};


export type MutationCreateOneContentArgs = {
  data: ContentCreateInput;
};


export type MutationCreateOneDeceasedArgs = {
  data: DeceasedCreateInput;
};


export type MutationCreateUploadUrlArgs = {
  destinationFolder?: InputMaybe<Scalars['String']['input']>;
  estateId?: InputMaybe<Scalars['String']['input']>;
  fileName: Scalars['String']['input'];
};


export type MutationCreateUserCompletelyArgs = {
  data: CreateUserInput;
};


export type MutationCreateUserCompletelyWithEstateArgs = {
  data: CreateUserInput;
};


export type MutationDeleteDocumentArgs = {
  estateId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};


export type MutationDeleteEstateArgs = {
  estateId: Scalars['String']['input'];
};


export type MutationDeleteExpenseArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteOneActionCardArgs = {
  where: ActionCardWhereUniqueInput;
};


export type MutationDeleteOneComponentArgs = {
  where: ComponentWhereUniqueInput;
};


export type MutationDeleteOneContentArgs = {
  where: ContentWhereUniqueInput;
};


export type MutationDeleteUserNotificationsArgs = {
  data: DeleteUserNotificationsInput;
};


export type MutationLinkAccountArgs = {
  componentId: Scalars['String']['input'];
  estateId?: InputMaybe<Scalars['String']['input']>;
  publicToken: Scalars['String']['input'];
};


export type MutationMarkAlixFeeAsInvoicedArgs = {
  estateId: Scalars['String']['input'];
};


export type MutationMarkNotificationsSeenArgs = {
  notificationIds: Array<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationMoveDocumentsArgs = {
  data: MoveDocumentsInput;
};


export type MutationPrefillFormTemplateArgs = {
  estateId: Scalars['String']['input'];
  templateId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationRegisterDeviceArgs = {
  deviceToken: Scalars['String']['input'];
};


export type MutationRenameDocumentArgs = {
  estateId?: InputMaybe<Scalars['String']['input']>;
  sourceKey: Scalars['String']['input'];
  targetKey: Scalars['String']['input'];
};


export type MutationResendInvitationArgs = {
  userId: Scalars['String']['input'];
};


export type MutationResetExpiredCognitoPasswordArgs = {
  userId: Scalars['String']['input'];
};


export type MutationRestoreDocumentArgs = {
  deleteMarkerId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};


export type MutationScrapeMissingMoneyArgs = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};


export type MutationSetAlixFeeAmountArgs = {
  data: SetAlixFeeAmountInput;
};


export type MutationSignUpUserArgs = {
  data: SignUpUserInput;
};


export type MutationStartActionCardArgs = {
  estateId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  reply?: InputMaybe<Scalars['String']['input']>;
};


export type MutationTextractAnalysisArgs = {
  featureTypes: Array<Scalars['String']['input']>;
  filePath: Scalars['String']['input'];
};


export type MutationTrackClickArgs = {
  element: Scalars['String']['input'];
  estateId?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<Scalars['JSON']['input']>;
};


export type MutationTrackPageViewArgs = {
  estateId?: InputMaybe<Scalars['String']['input']>;
  fromPush?: InputMaybe<Scalars['Boolean']['input']>;
  page: Scalars['String']['input'];
};


export type MutationTrackScrollArgs = {
  elementId?: InputMaybe<Scalars['String']['input']>;
  estateId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
};


export type MutationTrackSectionOpenedArgs = {
  estateId?: InputMaybe<Scalars['String']['input']>;
  section: Scalars['String']['input'];
};


export type MutationUpdateCurrentUserArgs = {
  data: UserUpdateInput;
};


export type MutationUpdateCurrentUserSsnArgs = {
  ssn: Scalars['String']['input'];
};


export type MutationUpdateDeceasedSsnArgs = {
  id: Scalars['String']['input'];
  ssn: Scalars['String']['input'];
};


export type MutationUpdateExpenseArgs = {
  data: UpdateExpenseInput;
  where: ExpenseWhereUniqueInput;
};


export type MutationUpdateOneActionCardArgs = {
  data: ActionCardUpdateInput;
  where: ActionCardWhereUniqueInput;
};


export type MutationUpdateOneComponentArgs = {
  data: ComponentUpdateInput;
  where: ComponentWhereUniqueInput;
};


export type MutationUpdateOneContentArgs = {
  data: ContentUpdateInput;
  where: ContentWhereUniqueInput;
};


export type MutationUpdateOneDeceasedArgs = {
  data: DeceasedUpdateInput;
  where: DeceasedWhereUniqueInput;
};


export type MutationUpdateOneEstateArgs = {
  data: EstateUpdateInput;
  where: EstateWhereUniqueInput;
};


export type MutationUpdateOneUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpdateUserEstateArgs = {
  data: ValidatedEstateUpdateInput;
  where?: InputMaybe<EstateWhereUniqueInput>;
};


export type MutationUpdateUserEstateDeceasedSsnArgs = {
  estateId?: InputMaybe<Scalars['String']['input']>;
  ssn: Scalars['String']['input'];
};


export type MutationUpdateUserIdpAttributesArgs = {
  data: UpdateUserIdpInput;
  userId: Scalars['String']['input'];
};


export type MutationUpdateUserSsnArgs = {
  id: Scalars['String']['input'];
  ssn: Scalars['String']['input'];
};

/** What user group (by role) the notification is targeted to. */
export enum NotificationGroup {
  All = 'ALL',
  Beneficiary = 'BENEFICIARY',
  Executor = 'EXECUTOR'
}

export type NotificationLogEntry = {
  __typename?: 'NotificationLogEntry';
  actionCardId?: Maybe<Scalars['String']['output']>;
  componentId?: Maybe<Scalars['String']['output']>;
  contentId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  estateId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  message: Scalars['String']['output'];
  notificationReceipts: Array<NotificationReceipt>;
  route?: Maybe<Scalars['String']['output']>;
  routingError?: Maybe<RoutingError>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type NotificationReceipt = {
  __typename?: 'NotificationReceipt';
  createdAt: Scalars['DateTimeISO']['output'];
  notificationId: Scalars['String']['output'];
  seenAt?: Maybe<Scalars['DateTimeISO']['output']>;
  user?: Maybe<NotificationUser>;
};

export type NotificationUser = {
  __typename?: 'NotificationUser';
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export enum NullsOrder {
  First = 'first',
  Last = 'last'
}

export type Query = {
  __typename?: 'Query';
  actionCard?: Maybe<ActionCard>;
  estate?: Maybe<Estate>;
  /**
   * Get the estate for the logged in user. If the user has multiple estates, an estateId can be provided.
   *       If estateId is not provided, the first estate for the user will be returned.
   */
  estateDistributionBreakdown?: Maybe<EstateDistributionBreakdown>;
  estates: Array<Estate>;
  formTemplate: FormTemplate;
  formTemplates: Array<FormTemplate>;
  /** Returns the Alix fee information */
  getAlixFee?: Maybe<AlixFee>;
  /** Get the component types/subtypes hierarchy. */
  getComponentTypes: Array<ComponentTypeItem>;
  getContent?: Maybe<Content>;
  /** Get the logged in user. */
  getCurrentUser?: Maybe<User>;
  getEstateComponent?: Maybe<Component>;
  /** ADMIN: Get all notifications for all users of an estate. */
  getEstateNotifications?: Maybe<Array<EstateNotification>>;
  /** Get the most recent EULA. */
  getMostRecentEULA?: Maybe<Eula>;
  /** ADMIN: Get all notifications for all users of an estate along with their receipts. */
  getNotificationLog: Array<NotificationLogEntry>;
  getSettlementPlan: SettlementPlan;
  /** ADMIN to get all non-deleted estates */
  getSimpleEstateList: Array<Estate>;
  /** Get count of unseen notifications of the logged in user. */
  getUnseenNotificationsCount: Scalars['Int']['output'];
  getUser?: Maybe<User>;
  /**
   * Get the estate for the logged in user. If the user has multiple estates, an estateId can be provided.
   *       If estateId is not provided, the first estate for the user will be returned.
   */
  getUserEstate?: Maybe<Estate>;
  /** Get active notifications of the logged in user. Set unseen: true to get only unseen notifications. */
  getUserEstateNotifications?: Maybe<Array<UserEstateNotification>>;
  /** Check if the EULA is current. */
  isEULAcurrent: Scalars['Boolean']['output'];
  /** List all documents marked for deletion. Admins must provide an estateId */
  listDeletedDocuments: Array<EstateDocument>;
  /** Get a signed url for document upload. Admins must provide an estateId */
  listDocuments: Array<EstateDocument>;
  users: Array<User>;
};


export type QueryActionCardArgs = {
  where: ActionCardWhereUniqueInput;
};


export type QueryEstateArgs = {
  where: EstateWhereUniqueInput;
};


export type QueryEstateDistributionBreakdownArgs = {
  estateId: Scalars['String']['input'];
};


export type QueryFormTemplateArgs = {
  templateId: Scalars['String']['input'];
};


export type QueryGetAlixFeeArgs = {
  estateId: Scalars['String']['input'];
};


export type QueryGetContentArgs = {
  where: ContentWhereUniqueInput;
};


export type QueryGetEstateComponentArgs = {
  componentId: Scalars['String']['input'];
};


export type QueryGetEstateNotificationsArgs = {
  estateId: Scalars['String']['input'];
};


export type QueryGetNotificationLogArgs = {
  estateId: Scalars['String']['input'];
};


export type QueryGetSettlementPlanArgs = {
  estateId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetUnseenNotificationsCountArgs = {
  estateId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryGetUserEstateArgs = {
  estateId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetUserEstateNotificationsArgs = {
  estateId?: InputMaybe<Scalars['String']['input']>;
  unseen?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListDeletedDocumentsArgs = {
  estateId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListDocumentsArgs = {
  directory?: InputMaybe<Scalars['String']['input']>;
  estateId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUsersArgs = {
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  where?: InputMaybe<UserWhereInput>;
};

export enum RelatedElementType {
  ActionCard = 'ActionCard',
  Content = 'Content'
}

export type RouteElementInput = {
  id: Scalars['ID']['input'];
  order: Scalars['Float']['input'];
  type: RouteElementType;
};

/** Entity type to which a Notification route will link */
export enum RouteElementType {
  ActionCard = 'ACTION_CARD',
  EstateComponent = 'ESTATE_COMPONENT',
  EstateContent = 'ESTATE_CONTENT'
}

export type RoutingError = {
  __typename?: 'RoutingError';
  message?: Maybe<Scalars['String']['output']>;
};

export type SetAlixFeeAmountInput = {
  amount: Scalars['Decimal']['input'];
  estateId: Scalars['String']['input'];
};

export type SettlementPlan = {
  __typename?: 'SettlementPlan';
  estimatedCompletionDate?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['ID']['output'];
  items: Array<SettlementPlanItem>;
};

export type SettlementPlanItem = {
  __typename?: 'SettlementPlanItem';
  count: Scalars['BigInt']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type SignUpUserInput = {
  channelPartner?: InputMaybe<Scalars['String']['input']>;
  deceasedDateOfBirth?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deceasedDateOfDeath?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deceasedFirstName?: InputMaybe<Scalars['String']['input']>;
  deceasedLastName?: InputMaybe<Scalars['String']['input']>;
  deceasedState?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  userRole?: InputMaybe<UserEstateRole>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type SortOrderInput = {
  nulls?: InputMaybe<NullsOrder>;
  sort: SortOrder;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type Task = {
  __typename?: 'Task';
  completeByDateEnd?: Maybe<Scalars['DateTimeISO']['output']>;
  completeByDateStart?: Maybe<Scalars['DateTimeISO']['output']>;
  completeByName?: Maybe<Scalars['String']['output']>;
  completedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  description?: Maybe<Scalars['String']['output']>;
  estateId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  important: Scalars['Boolean']['output'];
  level: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  onCompleteMessage?: Maybe<Scalars['String']['output']>;
  order: Scalars['Int']['output'];
  parentTaskId?: Maybe<Scalars['String']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  status: TaskStatus;
  subTasks?: Maybe<Array<Task>>;
  taskId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTimeISO']['output'];
  workflowTaskId?: Maybe<Scalars['String']['output']>;
};

export enum TaskStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  InProgress = 'IN_PROGRESS',
  NotReady = 'NOT_READY',
  Paused = 'PAUSED',
  Ready = 'READY'
}

export type UpdateExpenseInput = {
  amount?: InputMaybe<Scalars['Decimal']['input']>;
  componentId?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserIdpInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  EULAAcceptanceHistories?: Maybe<Array<EulaAcceptanceHistory>>;
  active: Scalars['Boolean']['output'];
  address?: Maybe<Scalars['String']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  dateOfBirth?: Maybe<Scalars['DateTimeISO']['output']>;
  deviceRegistration?: Maybe<DeviceRegistration>;
  email: Scalars['String']['output'];
  estates: Array<UsersOnEstates>;
  expenses?: Maybe<Array<Expense>>;
  externalId?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  forcePasswordReset: Scalars['Boolean']['output'];
  fullSsn?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  notifications: Array<UserNotification>;
  partnerId?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  ssnLast4?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  type: UserType;
  updatedAt: Scalars['DateTimeISO']['output'];
  zip?: Maybe<Scalars['String']['output']>;
};

export type UserCreateNestedOneWithoutComponentsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutContentsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
};

export type UserEstateNotification = {
  __typename?: 'UserEstateNotification';
  createdAt: Scalars['DateTimeISO']['output'];
  estateId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  route?: Maybe<Scalars['String']['output']>;
  routingError?: Maybe<RoutingError>;
  seenAt?: Maybe<Scalars['DateTimeISO']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  userId: Scalars['ID']['output'];
};

export enum UserEstateRole {
  Beneficiery = 'BENEFICIERY',
  Executor = 'EXECUTOR'
}

export type UserNotification = {
  __typename?: 'UserNotification';
  createdAt: Scalars['DateTimeISO']['output'];
  notification?: Maybe<EstateNotification>;
  notificationId: Scalars['String']['output'];
  seenAt?: Maybe<Scalars['DateTimeISO']['output']>;
  user?: Maybe<User>;
  userId: Scalars['String']['output'];
};

export type UserOrderByWithRelationInput = {
  active?: InputMaybe<SortOrder>;
  address?: InputMaybe<SortOrderInput>;
  avatar?: InputMaybe<SortOrderInput>;
  city?: InputMaybe<SortOrderInput>;
  country?: InputMaybe<SortOrderInput>;
  county?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  dateOfBirth?: InputMaybe<SortOrderInput>;
  email?: InputMaybe<SortOrder>;
  externalId?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrderInput>;
  forcePasswordReset?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrderInput>;
  partnerId?: InputMaybe<SortOrderInput>;
  phone?: InputMaybe<SortOrderInput>;
  state?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  zip?: InputMaybe<SortOrderInput>;
};

export enum UserType {
  Admin = 'ADMIN',
  Careteam = 'CARETEAM',
  Customer = 'CUSTOMER',
  Ops = 'OPS'
}

export type UserUpdateInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['DateTimeISO']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  estates?: InputMaybe<UsersOnEstatesUpdateManyWithoutUserNestedInput>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  partnerId?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<UserType>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type UserUpdateOneWithoutContentsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  address?: InputMaybe<StringNullableFilter>;
  avatar?: InputMaybe<StringNullableFilter>;
  city?: InputMaybe<StringNullableFilter>;
  country?: InputMaybe<StringNullableFilter>;
  county?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dateOfBirth?: InputMaybe<DateTimeNullableFilter>;
  email?: InputMaybe<StringFilter>;
  externalId?: InputMaybe<StringFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  forcePasswordReset?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  partnerId?: InputMaybe<StringNullableFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  state?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumUserTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  zip?: InputMaybe<StringNullableFilter>;
};

export type UserWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type UsersOnEstates = {
  __typename?: 'UsersOnEstates';
  estate: Estate;
  estateId: Scalars['String']['output'];
  user: User;
  userId: Scalars['String']['output'];
  userRole?: Maybe<UserEstateRole>;
};

export type UsersOnEstatesCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UsersOnEstatesWhereUniqueInput>>;
  create?: InputMaybe<Array<UsersOnEstatesCreateWithoutUserInput>>;
};

export type UsersOnEstatesCreateWithoutUserInput = {
  estate: EstateCreateNestedOneWithoutUsersInput;
  userRole?: InputMaybe<UserEstateRole>;
};

export type UsersOnEstatesOrderByWithRelationInput = {
  estateId?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
  userRole?: InputMaybe<SortOrderInput>;
};

export type UsersOnEstatesUpdateManyWithoutEstateNestedInput = {
  connect?: InputMaybe<Array<UsersOnEstatesWhereUniqueInput>>;
  delete?: InputMaybe<Array<UsersOnEstatesWhereUniqueInput>>;
};

export type UsersOnEstatesUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UsersOnEstatesWhereUniqueInput>>;
  create?: InputMaybe<Array<UsersOnEstatesCreateWithoutUserInput>>;
};

export type UsersOnEstatesUserIdEstateIdCompoundUniqueInput = {
  estateId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UsersOnEstatesWhereInput = {
  AND?: InputMaybe<Array<UsersOnEstatesWhereInput>>;
  NOT?: InputMaybe<Array<UsersOnEstatesWhereInput>>;
  OR?: InputMaybe<Array<UsersOnEstatesWhereInput>>;
  estateId?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
  userRole?: InputMaybe<EnumUserEstateRoleNullableFilter>;
};

export type UsersOnEstatesWhereUniqueInput = {
  userId_estateId?: InputMaybe<UsersOnEstatesUserIdEstateIdCompoundUniqueInput>;
};

export type ValidatedEstateUpdateInput = {
  components?: InputMaybe<ComponentUpdateManyWithoutEstateNestedInput>;
  deceased?: InputMaybe<DeceasedUpdateOneWithoutEstateNestedInput>;
  processInstanceId?: InputMaybe<Scalars['String']['input']>;
  users?: InputMaybe<UsersOnEstatesUpdateManyWithoutEstateNestedInput>;
};

export type AdminCreateOneEstateWithDeceasedMutationVariables = Exact<{
  data: EstateCreateInput;
}>;


export type AdminCreateOneEstateWithDeceasedMutation = { __typename?: 'Mutation', adminCreateEstate: { __typename?: 'Estate', id: string, deceased?: { __typename?: 'Deceased', firstName: string, lastName: string, dateOfBirth?: any | null, dateOfDeath?: any | null, state?: string | null } | null } };

export type AdminCreateUserWithEstateMutationVariables = Exact<{
  data: AdminCreateUserWithEstateInput;
}>;


export type AdminCreateUserWithEstateMutation = { __typename?: 'Mutation', adminCreateUserWithEstate?: { __typename?: 'User', email: string, firstName?: string | null, lastName?: string | null, id: string } | null };

export type CaptureLoginMutationVariables = Exact<{ [key: string]: never; }>;


export type CaptureLoginMutation = { __typename?: 'Mutation', captureLogin: boolean };

export type CaptureLoginFailureMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type CaptureLoginFailureMutation = { __typename?: 'Mutation', captureLoginFailure: boolean };

export type CreateFolderMutationVariables = Exact<{
  data: CreateFolderInput;
}>;


export type CreateFolderMutation = { __typename?: 'Mutation', createFolder: boolean };

export type CreateNotificationByGroupMutationVariables = Exact<{
  data: CreateNotificationByGroupInput;
}>;


export type CreateNotificationByGroupMutation = { __typename?: 'Mutation', createNotificationByGroup?: { __typename?: 'EstateNotification', title: string, message: string } | null };

export type CreateOneActionCardMutationVariables = Exact<{
  data: ActionCardCreateInput;
}>;


export type CreateOneActionCardMutation = { __typename?: 'Mutation', createOneActionCard: { __typename?: 'ActionCard', id: string } };

export type CreateOneComponentMutationVariables = Exact<{
  data: ComponentCreateInput;
}>;


export type CreateOneComponentMutation = { __typename?: 'Mutation', createOneComponent: { __typename?: 'Component', id: string, accountHolder?: string | null, accountNumber?: string | null, address?: string | null, appraisedValue?: any | null, balance?: any | null, billingPeriod?: string | null, charge?: any | null, city?: string | null, contactName?: string | null, contactType?: string | null, country?: string | null, createdById?: string | null, descriptor: string, email?: string | null, estateId: string, estimatedValue?: any | null, finalValue?: any | null, inTrust?: boolean | null, lastCheckDate?: any | null, lastPaymentAmount?: any | null, lastPaymentDate?: any | null, loanAmount?: any | null, mortgageBank?: string | null, name: string, paidOff?: boolean | null, phone?: string | null, state?: string | null, registrationExpiresAt?: any | null, status: ComponentStatus, subType: ComponentSubType, type: ComponentType, zip?: string | null } };

export type CreateOneContentMutationVariables = Exact<{
  data: ContentCreateInput;
}>;


export type CreateOneContentMutation = { __typename?: 'Mutation', createOneContent: { __typename?: 'Content', id: string } };

export type CreateOneDeceasedMutationVariables = Exact<{
  data: DeceasedCreateInput;
}>;


export type CreateOneDeceasedMutation = { __typename?: 'Mutation', createOneDeceased: { __typename?: 'Deceased', id: string, firstName: string, lastName: string, podState?: string | null } };

export type CreateUploadUrlMutationVariables = Exact<{
  destinationFolder?: InputMaybe<Scalars['String']['input']>;
  estateId?: InputMaybe<Scalars['String']['input']>;
  fileName: Scalars['String']['input'];
}>;


export type CreateUploadUrlMutation = { __typename?: 'Mutation', createUploadUrl: string };

export type CreateUserCompletelyMutationVariables = Exact<{
  data: CreateUserInput;
}>;


export type CreateUserCompletelyMutation = { __typename?: 'Mutation', createUserCompletely: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, email: string } };

export type CreateUserCompletelyWithEstateMutationVariables = Exact<{
  data: CreateUserInput;
}>;


export type CreateUserCompletelyWithEstateMutation = { __typename?: 'Mutation', createUserCompletelyWithEstate: { __typename?: 'User', id: string, email: string } };

export type CreateUserWithEstateMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateUserWithEstateMutation = { __typename?: 'Mutation', createUserWithEstate?: { __typename?: 'User', id: string, active: boolean, forcePasswordReset: boolean, type: UserType, email: string, dateOfBirth?: any | null, firstName?: string | null, lastName?: string | null, address?: string | null, city?: string | null, county?: string | null, state?: string | null, country?: string | null, phone?: string | null, partnerId?: string | null, createdAt: any, updatedAt: any } | null };

export type CustomerAddDeceasedToEstateMutationVariables = Exact<{
  data: ValidatedEstateUpdateInput;
  where?: InputMaybe<EstateWhereUniqueInput>;
}>;


export type CustomerAddDeceasedToEstateMutation = { __typename?: 'Mutation', updateUserEstate?: { __typename?: 'Estate', id: string, deceased?: { __typename?: 'Deceased', id: string, firstName: string, lastName: string } | null } | null };

export type DeleteDocumentMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteDocumentMutation = { __typename?: 'Mutation', deleteDocument: boolean };

export type DeleteOneActionCardMutationVariables = Exact<{
  where: ActionCardWhereUniqueInput;
}>;


export type DeleteOneActionCardMutation = { __typename?: 'Mutation', deleteOneActionCard?: { __typename?: 'ActionCard', id: string } | null };

export type DeleteOneComponentMutationVariables = Exact<{
  where: ComponentWhereUniqueInput;
}>;


export type DeleteOneComponentMutation = { __typename?: 'Mutation', deleteOneComponent?: { __typename?: 'Component', id: string } | null };

export type DeleteOneContentMutationVariables = Exact<{
  where: ContentWhereUniqueInput;
}>;


export type DeleteOneContentMutation = { __typename?: 'Mutation', deleteOneContent?: { __typename?: 'Content', id: string } | null };

export type DeleteUserNotificationsMutationVariables = Exact<{
  data: DeleteUserNotificationsInput;
}>;


export type DeleteUserNotificationsMutation = { __typename?: 'Mutation', deleteUserNotifications: { __typename?: 'DeleteUserNotificationsResult', estateId: string, estateNotificationId: string, message: string, title: string } };

export type MarkNotificationsSeenMutationVariables = Exact<{
  notificationIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
}>;


export type MarkNotificationsSeenMutation = { __typename?: 'Mutation', markNotificationsSeen: boolean };

export type MoveDocumentsMutationVariables = Exact<{
  data: MoveDocumentsInput;
}>;


export type MoveDocumentsMutation = { __typename?: 'Mutation', moveDocuments: boolean };

export type PrefillFormTemplateMutationVariables = Exact<{
  templateId: Scalars['String']['input'];
  estateId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
}>;


export type PrefillFormTemplateMutation = { __typename?: 'Mutation', prefillFormTemplate: { __typename?: 'FormTemplateLink', createdAt: string, id: string, url: string } };

export type RenameDocumentMutationVariables = Exact<{
  targetKey: Scalars['String']['input'];
  sourceKey: Scalars['String']['input'];
  estateId?: InputMaybe<Scalars['String']['input']>;
}>;


export type RenameDocumentMutation = { __typename?: 'Mutation', renameDocument: boolean };

export type RestoreDocumentMutationVariables = Exact<{
  deleteMarkerId: Scalars['String']['input'];
  restoreDocumentId: Scalars['String']['input'];
}>;


export type RestoreDocumentMutation = { __typename?: 'Mutation', restoreDocument: boolean };

export type SignUpUserMutationVariables = Exact<{
  data: SignUpUserInput;
}>;


export type SignUpUserMutation = { __typename?: 'Mutation', signUpUser: { __typename?: 'User', id: string, email: string } };

export type StartActionCardMutationVariables = Exact<{
  startActionCardId: Scalars['String']['input'];
  estateId?: InputMaybe<Scalars['String']['input']>;
  reply?: InputMaybe<Scalars['String']['input']>;
}>;


export type StartActionCardMutation = { __typename?: 'Mutation', startActionCard: { __typename?: 'ActionCard', id: string, estateId: string, componentId?: string | null, estateDetailId?: string | null, type: ActionCardType, status: ActionCardStatus, name: string, description?: string | null, assignee?: AssigneeType | null, formFieldName?: string | null, encryptedReply: boolean, appointmentTime?: any | null, appointmentLocation?: string | null, calendarIcs?: string | null, calendarLink?: string | null, documentDownloadUrl?: string | null, documentUploadUrl?: string | null, documentSignUrl?: string | null, mapUrl?: string | null, promoted: boolean, createdAt: any, updatedAt: any, estateDetail?: { __typename?: 'EstateDetail', encrypted: boolean, value: any } | null } };

export type TrackClickMutationVariables = Exact<{
  element: Scalars['String']['input'];
  estateId: Scalars['String']['input'];
}>;


export type TrackClickMutation = { __typename?: 'Mutation', trackClick: boolean };

export type TrackPageViewMutationVariables = Exact<{
  page: Scalars['String']['input'];
  estateId: Scalars['String']['input'];
}>;


export type TrackPageViewMutation = { __typename?: 'Mutation', trackPageView: boolean };

export type TrackScrollMutationVariables = Exact<{
  name: Scalars['String']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
  estateId?: InputMaybe<Scalars['String']['input']>;
  elementId?: InputMaybe<Scalars['String']['input']>;
}>;


export type TrackScrollMutation = { __typename?: 'Mutation', trackScroll: boolean };

export type TrackSectionOpenedMutationVariables = Exact<{
  section: Scalars['String']['input'];
  estateId: Scalars['String']['input'];
}>;


export type TrackSectionOpenedMutation = { __typename?: 'Mutation', trackSectionOpened: boolean };

export type UpdateOneActionCardMutationVariables = Exact<{
  data: ActionCardUpdateInput;
  where: ActionCardWhereUniqueInput;
}>;


export type UpdateOneActionCardMutation = { __typename?: 'Mutation', updateOneActionCard?: { __typename?: 'ActionCard', id: string } | null };

export type UpdateOneComponentMutationVariables = Exact<{
  data: ComponentUpdateInput;
  where: ComponentWhereUniqueInput;
}>;


export type UpdateOneComponentMutation = { __typename?: 'Mutation', updateOneComponent?: { __typename?: 'Component', id: string } | null };

export type UpdateOneContentMutationVariables = Exact<{
  data: ContentUpdateInput;
  where: ContentWhereUniqueInput;
}>;


export type UpdateOneContentMutation = { __typename?: 'Mutation', updateOneContent?: { __typename?: 'Content', id: string, header: string, status: ContentStatus, completeBy: any, completedAt?: any | null } | null };

export type UpdateOneDeceasedMutationVariables = Exact<{
  data: DeceasedUpdateInput;
  where: DeceasedWhereUniqueInput;
}>;


export type UpdateOneDeceasedMutation = { __typename?: 'Mutation', updateOneDeceased?: { __typename?: 'Deceased', id: string, firstName: string, lastName: string, podState?: string | null, state?: string | null, dateOfBirth?: any | null, dateOfDeath?: any | null } | null };

export type UpdateOneEstateMutationVariables = Exact<{
  data: EstateUpdateInput;
  where: EstateWhereUniqueInput;
}>;


export type UpdateOneEstateMutation = { __typename?: 'Mutation', updateOneEstate?: { __typename?: 'Estate', id: string } | null };

export type UpdateOneUserMutationVariables = Exact<{
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
}>;


export type UpdateOneUserMutation = { __typename?: 'Mutation', updateOneUser?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, email: string } | null };

export type AdminGetUsersOnEstateQueryVariables = Exact<{
  where: EstateWhereUniqueInput;
  orderBy?: InputMaybe<Array<UsersOnEstatesOrderByWithRelationInput> | UsersOnEstatesOrderByWithRelationInput>;
}>;


export type AdminGetUsersOnEstateQuery = { __typename?: 'Query', estate?: { __typename?: 'Estate', users: Array<{ __typename?: 'UsersOnEstates', userRole?: UserEstateRole | null, user: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } }> } | null };

export type FormTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type FormTemplatesQuery = { __typename?: 'Query', formTemplates: Array<{ __typename?: 'FormTemplate', id: string, title: string }> };

export type GetActionCardQueryVariables = Exact<{
  where: ActionCardWhereUniqueInput;
}>;


export type GetActionCardQuery = { __typename?: 'Query', actionCard?: { __typename?: 'ActionCard', id: string, estateId: string, componentId?: string | null, estateDetailId?: string | null, type: ActionCardType, status: ActionCardStatus, name: string, description?: string | null, assignee?: AssigneeType | null, formFieldName?: string | null, encryptedReply: boolean, appointmentTime?: any | null, appointmentLocation?: string | null, calendarIcs?: string | null, calendarLink?: string | null, documentDownloadUrl?: string | null, documentUploadUrl?: string | null, documentSignUrl?: string | null, mapUrl?: string | null, promoted: boolean, createdAt: any, updatedAt: any, estateDetail?: { __typename?: 'EstateDetail', value: any, id: string, decryptedValue?: any | null } | null } | null };

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = { __typename?: 'Query', getCurrentUser?: { __typename?: 'User', id: string, forcePasswordReset: boolean, active: boolean, firstName?: string | null, lastName?: string | null, email: string, avatar?: string | null } | null };

export type GetEstateComponentQueryVariables = Exact<{
  componentId: Scalars['String']['input'];
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput> | ContentOrderByWithRelationInput>;
}>;


export type GetEstateComponentQuery = { __typename?: 'Query', getEstateComponent?: { __typename?: 'Component', id: string, name: string, type: ComponentType, subType: ComponentSubType, descriptor: string, contents: Array<{ __typename?: 'Content', id: string, header: string, body: string, status: ContentStatus, displayDate: string, completeBy: any, completeByType: ContentCompleteByType, completedAt?: any | null, user?: { __typename?: 'User', firstName?: string | null, lastName?: string | null, avatar?: string | null } | null }>, actionCards: Array<{ __typename?: 'ActionCard', id: string, name: string, status: ActionCardStatus, type: ActionCardType }>, notifications: Array<{ __typename?: 'EstateNotification', id: string, title: string, message: string }> } | null };

export type GetEstateComponentSummaryQueryVariables = Exact<{
  componentId: Scalars['String']['input'];
}>;


export type GetEstateComponentSummaryQuery = { __typename?: 'Query', getEstateComponent?: { __typename?: 'Component', id: string, accountHolder?: string | null, accountNumber?: string | null, address?: string | null, city?: string | null, country?: string | null, descriptor: string, estimatedValue?: any | null, finalValue?: any | null, name: string, state?: string | null, status: ComponentStatus, subType: ComponentSubType, type: ComponentType, zip?: string | null } | null };

export type GetEstateNotificationsQueryVariables = Exact<{
  estateId: Scalars['String']['input'];
}>;


export type GetEstateNotificationsQuery = { __typename?: 'Query', getEstateNotifications?: Array<{ __typename?: 'EstateNotification', componentId?: string | null, createdAt: any, estateId?: string | null, id: string, message: string, title: string, updatedAt: any, userNotifications: Array<{ __typename?: 'UserNotification', userId: string, seenAt?: any | null, user?: { __typename?: 'User', firstName?: string | null, lastName?: string | null, estates: Array<{ __typename?: 'UsersOnEstates', userRole?: UserEstateRole | null }> } | null }> }> | null };

export type GetEstatePieDataQueryVariables = Exact<{
  estateId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetEstatePieDataQuery = { __typename?: 'Query', getUserEstate?: { __typename?: 'Estate', id: string, componentBreakDown?: Array<{ __typename?: 'ComponentBreakDown', type: string, subType: string, sum: any, percentage: any, count: any, total: any }> | null } | null };

export type GetKeyDatesQueryVariables = Exact<{
  estateId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetKeyDatesQuery = { __typename?: 'Query', getUserEstate?: { __typename?: 'Estate', keyDates: Array<{ __typename?: 'Content', id: string, header: string, body: string, status: ContentStatus, displayDate: string, componentId?: string | null, completeBy: any, completeByType: ContentCompleteByType, completedAt?: any | null, user?: { __typename?: 'User', firstName?: string | null, lastName?: string | null, avatar?: string | null } | null }> } | null };

export type GetMostRecentEulaQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMostRecentEulaQuery = { __typename?: 'Query', getMostRecentEULA?: { __typename?: 'EULA', id: string, version: string, pdfLink?: string | null } | null };

export type GetNotificationLogQueryVariables = Exact<{
  estateId: Scalars['String']['input'];
}>;


export type GetNotificationLogQuery = { __typename?: 'Query', getNotificationLog: Array<{ __typename?: 'NotificationLogEntry', estateId?: string | null, id: string, title: string, message: string, createdAt: any, updatedAt: any, actionCardId?: string | null, componentId?: string | null, contentId?: string | null, route?: string | null, notificationReceipts: Array<{ __typename?: 'NotificationReceipt', createdAt: any, notificationId: string, seenAt?: any | null, user?: { __typename?: 'NotificationUser', userId: string, firstName: string, lastName: string } | null }>, routingError?: { __typename?: 'RoutingError', message?: string | null } | null }> };

export type GetOneContentQueryVariables = Exact<{
  where: ContentWhereUniqueInput;
}>;


export type GetOneContentQuery = { __typename?: 'Query', getContent?: { __typename?: 'Content', id: string, completeByType: ContentCompleteByType, completeBy: any, displayDate: string, body: string, componentId?: string | null, header: string, status: ContentStatus, userId?: string | null, type?: ContentType | null, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, avatar?: string | null } | null } | null };

export type GetRecentActivityQueryVariables = Exact<{
  estateId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetRecentActivityQuery = { __typename?: 'Query', getUserEstate?: { __typename?: 'Estate', recentActivity: Array<{ __typename?: 'Content', id: string, header: string, body: string, status: ContentStatus, displayDate: string, componentId?: string | null, completeBy: any, completeByType: ContentCompleteByType, completedAt?: any | null, type?: ContentType | null, user?: { __typename?: 'User', firstName?: string | null, lastName?: string | null, avatar?: string | null } | null }> } | null };

export type GetSimpleEstateListQueryVariables = Exact<{
  where?: InputMaybe<UsersOnEstatesWhereInput>;
}>;


export type GetSimpleEstateListQuery = { __typename?: 'Query', estates: Array<{ __typename?: 'Estate', id: string, deceased?: { __typename?: 'Deceased', id: string, firstName: string, lastName: string } | null, users: Array<{ __typename?: 'UsersOnEstates', user: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } }> }> };

export type GetUnseenNotificationsCountQueryVariables = Exact<{
  estateId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetUnseenNotificationsCountQuery = { __typename?: 'Query', getUnseenNotificationsCount: number };

export type GetUserQueryVariables = Exact<{
  where: UserWhereUniqueInput;
}>;


export type GetUserQuery = { __typename?: 'Query', getUser?: { __typename?: 'User', id: string, active: boolean, type: UserType, email: string, dateOfBirth?: any | null, firstName?: string | null, lastName?: string | null, address?: string | null, city?: string | null, county?: string | null, state?: string | null, country?: string | null, phone?: string | null, partnerId?: string | null, ssnLast4?: string | null, fullSsn?: string | null, createdAt: any } | null };

export type GetUserActionCardQueryVariables = Exact<{
  where?: InputMaybe<ActionCardWhereInput>;
  estateId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetUserActionCardQuery = { __typename?: 'Query', getUserEstate?: { __typename?: 'Estate', actionCards: Array<{ __typename?: 'ActionCard', id: string, estateId: string, componentId?: string | null, completedAt?: any | null, estateDetailId?: string | null, taskId?: string | null, type: ActionCardType, status: ActionCardStatus, name: string, description?: string | null, encryptedReply: boolean, locked: boolean, assignee?: AssigneeType | null, formFieldName?: string | null, appointmentTime?: any | null, appointmentLocation?: string | null, calendarIcs?: string | null, calendarLink?: string | null, documentDownloadUrl?: string | null, documentUploadUrl?: string | null, documentSignUrl?: string | null, mapUrl?: string | null, createdAt: any, updatedAt: any, component?: { __typename?: 'Component', id: string, name: string, subType: ComponentSubType, type: ComponentType, accountNumber?: string | null, descriptor: string } | null, task?: { __typename?: 'Task', name: string, shortName?: string | null } | null, estateDetail?: { __typename?: 'EstateDetail', id: string, name: string, updatedAt: any, value: any } | null }> } | null };

export type GetUserEstateActionCardsQueryVariables = Exact<{
  estateId?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<ActionCardWhereInput>;
  orderBy?: InputMaybe<Array<ActionCardOrderByWithRelationInput> | ActionCardOrderByWithRelationInput>;
}>;


export type GetUserEstateActionCardsQuery = { __typename?: 'Query', getUserEstate?: { __typename?: 'Estate', id: string, deceased?: { __typename?: 'Deceased', id: string, firstName: string, lastName: string, podState?: string | null, state?: string | null, dateOfBirth?: any | null, dateOfDeath?: any | null } | null, componentBreakDown?: Array<{ __typename?: 'ComponentBreakDown', type: string, subType: string, sum: any, percentage: any, count: any, total: any }> | null, actionCards: Array<{ __typename?: 'ActionCard', id: string, estateId: string, componentId?: string | null, completedAt?: any | null, estateDetailId?: string | null, type: ActionCardType, status: ActionCardStatus, name: string, description?: string | null, encryptedReply: boolean, locked: boolean, assignee?: AssigneeType | null, formFieldName?: string | null, appointmentTime?: any | null, appointmentLocation?: string | null, calendarIcs?: string | null, calendarLink?: string | null, documentDownloadUrl?: string | null, documentUploadUrl?: string | null, documentSignUrl?: string | null, mapUrl?: string | null, createdAt: any, updatedAt: any, component?: { __typename?: 'Component', id: string, name: string, subType: ComponentSubType, type: ComponentType, accountNumber?: string | null, descriptor: string } | null, task?: { __typename?: 'Task', name: string, shortName?: string | null } | null, estateDetail?: { __typename?: 'EstateDetail', id: string, name: string, updatedAt: any, value: any } | null }> } | null };

export type GetUserEstateIdQueryVariables = Exact<{
  estateId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetUserEstateIdQuery = { __typename?: 'Query', getUserEstate?: { __typename?: 'Estate', id: string } | null };

export type GetUserEstateNotificationsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']['input']>;
  estateId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetUserEstateNotificationsQuery = { __typename?: 'Query', getUserEstateNotifications?: Array<{ __typename?: 'UserEstateNotification', createdAt: any, estateId: string, id: string, message: string, route?: string | null, seenAt?: any | null, title: string, updatedAt: any, userId: string, routingError?: { __typename?: 'RoutingError', message?: string | null } | null }> | null };

export type GetUserEstateQueryVariables = Exact<{
  estateId?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<UsersOnEstatesWhereInput>;
}>;


export type GetUserEstateQuery = { __typename?: 'Query', getUserEstate?: { __typename?: 'Estate', id: string, hasProbate?: boolean | null, hasTrust?: boolean | null, hasWill?: boolean | null, deceased?: { __typename?: 'Deceased', id: string, firstName: string, lastName: string, podState?: string | null, state?: string | null, dateOfBirth?: any | null, dateOfDeath?: any | null } | null, estateComponents: Array<{ __typename?: 'Component', id: string, estateId: string, type: ComponentType, subType: ComponentSubType, name: string, descriptor: string, finalValue?: any | null, estimatedValue?: any | null, appraisedValue?: any | null, loanAmount?: any | null, mortgageBank?: string | null, accountNumber?: string | null, accountHolder?: string | null, address?: string | null, city?: string | null, state?: string | null, zip?: string | null, country?: string | null, contactName?: string | null, contactType?: string | null, phone?: string | null, email?: string | null, balance?: any | null, lastCheckDate?: any | null, inTrust?: boolean | null, createdById?: string | null, status: ComponentStatus, completedAt?: any | null, registrationExpiresAt?: any | null, paidOff?: boolean | null, lastPaymentAmount?: any | null, lastPaymentDate?: any | null, billingPeriod?: string | null, charge?: any | null }>, componentBreakDown?: Array<{ __typename?: 'ComponentBreakDown', type: string, subType: string, sum: any, percentage: any, count: any, total: any }> | null, users: Array<{ __typename?: 'UsersOnEstates', user: { __typename?: 'User', firstName?: string | null, lastName?: string | null, id: string } }> } | null };

export type GetUsersListQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput> | UserOrderByWithRelationInput>;
  where?: InputMaybe<UserWhereInput>;
}>;


export type GetUsersListQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, email: string, address?: string | null, avatar?: string | null }> };

export type IsEulaCurrentQueryVariables = Exact<{ [key: string]: never; }>;


export type IsEulaCurrentQuery = { __typename?: 'Query', isEULAcurrent: boolean };

export type ListDeletedDocumentsQueryVariables = Exact<{
  estateId?: InputMaybe<Scalars['String']['input']>;
}>;


export type ListDeletedDocumentsQuery = { __typename?: 'Query', listDeletedDocuments: Array<{ __typename?: 'EstateDocument', id: string, isDir: boolean, modDate: any, name: string, signedUrl?: string | null, deleteMarkerId?: string | null }> };

export type ListDocumentsQueryVariables = Exact<{
  estateId?: InputMaybe<Scalars['String']['input']>;
  directory?: InputMaybe<Scalars['String']['input']>;
}>;


export type ListDocumentsQuery = { __typename?: 'Query', listDocuments: Array<{ __typename?: 'EstateDocument', id: string, isDir: boolean, modDate: any, name: string, size: number, signedUrl?: string | null, uploadedAt?: any | null, contentType?: string | null }> };

export type GetEstateDistributionBreakdownQueryVariables = Exact<{
  estateId: Scalars['String']['input'];
}>;


export type GetEstateDistributionBreakdownQuery = { __typename?: 'Query', estateDistributionBreakdown?: { __typename?: 'EstateDistributionBreakdown', debts: any, totalValue: any, estimatedDistribution: any, feeCalculationInfo?: { __typename?: 'Info', message: string, title: string } | null, distributionInfo?: { __typename?: 'Info', message: string, title: string } | null, alixFee: { __typename?: 'AlixFeeDisplay', amount: any, status: string, updatedAt: any } } | null };


export const AdminCreateOneEstateWithDeceasedDocument = gql`
    mutation AdminCreateOneEstateWithDeceased($data: EstateCreateInput!) {
  adminCreateEstate(data: $data) {
    id
    deceased {
      firstName
      lastName
      dateOfBirth
      dateOfDeath
      state
    }
  }
}
    `;
export type AdminCreateOneEstateWithDeceasedMutationFn = Apollo.MutationFunction<AdminCreateOneEstateWithDeceasedMutation, AdminCreateOneEstateWithDeceasedMutationVariables>;

/**
 * __useAdminCreateOneEstateWithDeceasedMutation__
 *
 * To run a mutation, you first call `useAdminCreateOneEstateWithDeceasedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateOneEstateWithDeceasedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateOneEstateWithDeceasedMutation, { data, loading, error }] = useAdminCreateOneEstateWithDeceasedMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminCreateOneEstateWithDeceasedMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateOneEstateWithDeceasedMutation, AdminCreateOneEstateWithDeceasedMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<AdminCreateOneEstateWithDeceasedMutation, AdminCreateOneEstateWithDeceasedMutationVariables>(AdminCreateOneEstateWithDeceasedDocument, options);
}
export type AdminCreateOneEstateWithDeceasedMutationHookResult = ReturnType<typeof useAdminCreateOneEstateWithDeceasedMutation>;
export type AdminCreateOneEstateWithDeceasedMutationResult = Apollo.MutationResult<AdminCreateOneEstateWithDeceasedMutation>;
export type AdminCreateOneEstateWithDeceasedMutationOptions = Apollo.BaseMutationOptions<AdminCreateOneEstateWithDeceasedMutation, AdminCreateOneEstateWithDeceasedMutationVariables>;
export const AdminCreateUserWithEstateDocument = gql`
    mutation AdminCreateUserWithEstate($data: AdminCreateUserWithEstateInput!) {
  adminCreateUserWithEstate(data: $data) {
    email
    firstName
    lastName
    id
  }
}
    `;
export type AdminCreateUserWithEstateMutationFn = Apollo.MutationFunction<AdminCreateUserWithEstateMutation, AdminCreateUserWithEstateMutationVariables>;

/**
 * __useAdminCreateUserWithEstateMutation__
 *
 * To run a mutation, you first call `useAdminCreateUserWithEstateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateUserWithEstateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateUserWithEstateMutation, { data, loading, error }] = useAdminCreateUserWithEstateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminCreateUserWithEstateMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateUserWithEstateMutation, AdminCreateUserWithEstateMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<AdminCreateUserWithEstateMutation, AdminCreateUserWithEstateMutationVariables>(AdminCreateUserWithEstateDocument, options);
}
export type AdminCreateUserWithEstateMutationHookResult = ReturnType<typeof useAdminCreateUserWithEstateMutation>;
export type AdminCreateUserWithEstateMutationResult = Apollo.MutationResult<AdminCreateUserWithEstateMutation>;
export type AdminCreateUserWithEstateMutationOptions = Apollo.BaseMutationOptions<AdminCreateUserWithEstateMutation, AdminCreateUserWithEstateMutationVariables>;
export const CaptureLoginDocument = gql`
    mutation CaptureLogin {
  captureLogin
}
    `;
export type CaptureLoginMutationFn = Apollo.MutationFunction<CaptureLoginMutation, CaptureLoginMutationVariables>;

/**
 * __useCaptureLoginMutation__
 *
 * To run a mutation, you first call `useCaptureLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaptureLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [captureLoginMutation, { data, loading, error }] = useCaptureLoginMutation({
 *   variables: {
 *   },
 * });
 */
export function useCaptureLoginMutation(baseOptions?: Apollo.MutationHookOptions<CaptureLoginMutation, CaptureLoginMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<CaptureLoginMutation, CaptureLoginMutationVariables>(CaptureLoginDocument, options);
}
export type CaptureLoginMutationHookResult = ReturnType<typeof useCaptureLoginMutation>;
export type CaptureLoginMutationResult = Apollo.MutationResult<CaptureLoginMutation>;
export type CaptureLoginMutationOptions = Apollo.BaseMutationOptions<CaptureLoginMutation, CaptureLoginMutationVariables>;
export const CaptureLoginFailureDocument = gql`
    mutation CaptureLoginFailure($email: String!) {
  captureLoginFailure(email: $email)
}
    `;
export type CaptureLoginFailureMutationFn = Apollo.MutationFunction<CaptureLoginFailureMutation, CaptureLoginFailureMutationVariables>;

/**
 * __useCaptureLoginFailureMutation__
 *
 * To run a mutation, you first call `useCaptureLoginFailureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaptureLoginFailureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [captureLoginFailureMutation, { data, loading, error }] = useCaptureLoginFailureMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCaptureLoginFailureMutation(baseOptions?: Apollo.MutationHookOptions<CaptureLoginFailureMutation, CaptureLoginFailureMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<CaptureLoginFailureMutation, CaptureLoginFailureMutationVariables>(CaptureLoginFailureDocument, options);
}
export type CaptureLoginFailureMutationHookResult = ReturnType<typeof useCaptureLoginFailureMutation>;
export type CaptureLoginFailureMutationResult = Apollo.MutationResult<CaptureLoginFailureMutation>;
export type CaptureLoginFailureMutationOptions = Apollo.BaseMutationOptions<CaptureLoginFailureMutation, CaptureLoginFailureMutationVariables>;
export const CreateFolderDocument = gql`
    mutation CreateFolder($data: CreateFolderInput!) {
  createFolder(data: $data)
}
    `;
export type CreateFolderMutationFn = Apollo.MutationFunction<CreateFolderMutation, CreateFolderMutationVariables>;

/**
 * __useCreateFolderMutation__
 *
 * To run a mutation, you first call `useCreateFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFolderMutation, { data, loading, error }] = useCreateFolderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateFolderMutation(baseOptions?: Apollo.MutationHookOptions<CreateFolderMutation, CreateFolderMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<CreateFolderMutation, CreateFolderMutationVariables>(CreateFolderDocument, options);
}
export type CreateFolderMutationHookResult = ReturnType<typeof useCreateFolderMutation>;
export type CreateFolderMutationResult = Apollo.MutationResult<CreateFolderMutation>;
export type CreateFolderMutationOptions = Apollo.BaseMutationOptions<CreateFolderMutation, CreateFolderMutationVariables>;
export const CreateNotificationByGroupDocument = gql`
    mutation CreateNotificationByGroup($data: CreateNotificationByGroupInput!) {
  createNotificationByGroup(data: $data) {
    title
    message
  }
}
    `;
export type CreateNotificationByGroupMutationFn = Apollo.MutationFunction<CreateNotificationByGroupMutation, CreateNotificationByGroupMutationVariables>;

/**
 * __useCreateNotificationByGroupMutation__
 *
 * To run a mutation, you first call `useCreateNotificationByGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotificationByGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotificationByGroupMutation, { data, loading, error }] = useCreateNotificationByGroupMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateNotificationByGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateNotificationByGroupMutation, CreateNotificationByGroupMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<CreateNotificationByGroupMutation, CreateNotificationByGroupMutationVariables>(CreateNotificationByGroupDocument, options);
}
export type CreateNotificationByGroupMutationHookResult = ReturnType<typeof useCreateNotificationByGroupMutation>;
export type CreateNotificationByGroupMutationResult = Apollo.MutationResult<CreateNotificationByGroupMutation>;
export type CreateNotificationByGroupMutationOptions = Apollo.BaseMutationOptions<CreateNotificationByGroupMutation, CreateNotificationByGroupMutationVariables>;
export const CreateOneActionCardDocument = gql`
    mutation CreateOneActionCard($data: ActionCardCreateInput!) {
  createOneActionCard(data: $data) {
    id
  }
}
    `;
export type CreateOneActionCardMutationFn = Apollo.MutationFunction<CreateOneActionCardMutation, CreateOneActionCardMutationVariables>;

/**
 * __useCreateOneActionCardMutation__
 *
 * To run a mutation, you first call `useCreateOneActionCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneActionCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneActionCardMutation, { data, loading, error }] = useCreateOneActionCardMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneActionCardMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneActionCardMutation, CreateOneActionCardMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<CreateOneActionCardMutation, CreateOneActionCardMutationVariables>(CreateOneActionCardDocument, options);
}
export type CreateOneActionCardMutationHookResult = ReturnType<typeof useCreateOneActionCardMutation>;
export type CreateOneActionCardMutationResult = Apollo.MutationResult<CreateOneActionCardMutation>;
export type CreateOneActionCardMutationOptions = Apollo.BaseMutationOptions<CreateOneActionCardMutation, CreateOneActionCardMutationVariables>;
export const CreateOneComponentDocument = gql`
    mutation CreateOneComponent($data: ComponentCreateInput!) {
  createOneComponent(data: $data) {
    id
    accountHolder
    accountNumber
    address
    appraisedValue
    balance
    billingPeriod
    charge
    city
    contactName
    contactType
    country
    createdById
    descriptor
    email
    estateId
    estimatedValue
    finalValue
    inTrust
    lastCheckDate
    lastPaymentAmount
    lastPaymentDate
    loanAmount
    mortgageBank
    name
    paidOff
    phone
    state
    registrationExpiresAt
    status
    subType
    type
    zip
  }
}
    `;
export type CreateOneComponentMutationFn = Apollo.MutationFunction<CreateOneComponentMutation, CreateOneComponentMutationVariables>;

/**
 * __useCreateOneComponentMutation__
 *
 * To run a mutation, you first call `useCreateOneComponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneComponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneComponentMutation, { data, loading, error }] = useCreateOneComponentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneComponentMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneComponentMutation, CreateOneComponentMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<CreateOneComponentMutation, CreateOneComponentMutationVariables>(CreateOneComponentDocument, options);
}
export type CreateOneComponentMutationHookResult = ReturnType<typeof useCreateOneComponentMutation>;
export type CreateOneComponentMutationResult = Apollo.MutationResult<CreateOneComponentMutation>;
export type CreateOneComponentMutationOptions = Apollo.BaseMutationOptions<CreateOneComponentMutation, CreateOneComponentMutationVariables>;
export const CreateOneContentDocument = gql`
    mutation CreateOneContent($data: ContentCreateInput!) {
  createOneContent(data: $data) {
    id
  }
}
    `;
export type CreateOneContentMutationFn = Apollo.MutationFunction<CreateOneContentMutation, CreateOneContentMutationVariables>;

/**
 * __useCreateOneContentMutation__
 *
 * To run a mutation, you first call `useCreateOneContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneContentMutation, { data, loading, error }] = useCreateOneContentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneContentMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneContentMutation, CreateOneContentMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<CreateOneContentMutation, CreateOneContentMutationVariables>(CreateOneContentDocument, options);
}
export type CreateOneContentMutationHookResult = ReturnType<typeof useCreateOneContentMutation>;
export type CreateOneContentMutationResult = Apollo.MutationResult<CreateOneContentMutation>;
export type CreateOneContentMutationOptions = Apollo.BaseMutationOptions<CreateOneContentMutation, CreateOneContentMutationVariables>;
export const CreateOneDeceasedDocument = gql`
    mutation CreateOneDeceased($data: DeceasedCreateInput!) {
  createOneDeceased(data: $data) {
    id
    firstName
    lastName
    podState
  }
}
    `;
export type CreateOneDeceasedMutationFn = Apollo.MutationFunction<CreateOneDeceasedMutation, CreateOneDeceasedMutationVariables>;

/**
 * __useCreateOneDeceasedMutation__
 *
 * To run a mutation, you first call `useCreateOneDeceasedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneDeceasedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneDeceasedMutation, { data, loading, error }] = useCreateOneDeceasedMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneDeceasedMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneDeceasedMutation, CreateOneDeceasedMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<CreateOneDeceasedMutation, CreateOneDeceasedMutationVariables>(CreateOneDeceasedDocument, options);
}
export type CreateOneDeceasedMutationHookResult = ReturnType<typeof useCreateOneDeceasedMutation>;
export type CreateOneDeceasedMutationResult = Apollo.MutationResult<CreateOneDeceasedMutation>;
export type CreateOneDeceasedMutationOptions = Apollo.BaseMutationOptions<CreateOneDeceasedMutation, CreateOneDeceasedMutationVariables>;
export const CreateUploadUrlDocument = gql`
    mutation CreateUploadUrl($destinationFolder: String, $estateId: String, $fileName: String!) {
  createUploadUrl(
    destinationFolder: $destinationFolder
    estateId: $estateId
    fileName: $fileName
  )
}
    `;
export type CreateUploadUrlMutationFn = Apollo.MutationFunction<CreateUploadUrlMutation, CreateUploadUrlMutationVariables>;

/**
 * __useCreateUploadUrlMutation__
 *
 * To run a mutation, you first call `useCreateUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUploadUrlMutation, { data, loading, error }] = useCreateUploadUrlMutation({
 *   variables: {
 *      destinationFolder: // value for 'destinationFolder'
 *      estateId: // value for 'estateId'
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useCreateUploadUrlMutation(baseOptions?: Apollo.MutationHookOptions<CreateUploadUrlMutation, CreateUploadUrlMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<CreateUploadUrlMutation, CreateUploadUrlMutationVariables>(CreateUploadUrlDocument, options);
}
export type CreateUploadUrlMutationHookResult = ReturnType<typeof useCreateUploadUrlMutation>;
export type CreateUploadUrlMutationResult = Apollo.MutationResult<CreateUploadUrlMutation>;
export type CreateUploadUrlMutationOptions = Apollo.BaseMutationOptions<CreateUploadUrlMutation, CreateUploadUrlMutationVariables>;
export const CreateUserCompletelyDocument = gql`
    mutation CreateUserCompletely($data: CreateUserInput!) {
  createUserCompletely(data: $data) {
    id
    firstName
    lastName
    email
  }
}
    `;
export type CreateUserCompletelyMutationFn = Apollo.MutationFunction<CreateUserCompletelyMutation, CreateUserCompletelyMutationVariables>;

/**
 * __useCreateUserCompletelyMutation__
 *
 * To run a mutation, you first call `useCreateUserCompletelyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserCompletelyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserCompletelyMutation, { data, loading, error }] = useCreateUserCompletelyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserCompletelyMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserCompletelyMutation, CreateUserCompletelyMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<CreateUserCompletelyMutation, CreateUserCompletelyMutationVariables>(CreateUserCompletelyDocument, options);
}
export type CreateUserCompletelyMutationHookResult = ReturnType<typeof useCreateUserCompletelyMutation>;
export type CreateUserCompletelyMutationResult = Apollo.MutationResult<CreateUserCompletelyMutation>;
export type CreateUserCompletelyMutationOptions = Apollo.BaseMutationOptions<CreateUserCompletelyMutation, CreateUserCompletelyMutationVariables>;
export const CreateUserCompletelyWithEstateDocument = gql`
    mutation CreateUserCompletelyWithEstate($data: CreateUserInput!) {
  createUserCompletelyWithEstate(data: $data) {
    id
    email
  }
}
    `;
export type CreateUserCompletelyWithEstateMutationFn = Apollo.MutationFunction<CreateUserCompletelyWithEstateMutation, CreateUserCompletelyWithEstateMutationVariables>;

/**
 * __useCreateUserCompletelyWithEstateMutation__
 *
 * To run a mutation, you first call `useCreateUserCompletelyWithEstateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserCompletelyWithEstateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserCompletelyWithEstateMutation, { data, loading, error }] = useCreateUserCompletelyWithEstateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserCompletelyWithEstateMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserCompletelyWithEstateMutation, CreateUserCompletelyWithEstateMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<CreateUserCompletelyWithEstateMutation, CreateUserCompletelyWithEstateMutationVariables>(CreateUserCompletelyWithEstateDocument, options);
}
export type CreateUserCompletelyWithEstateMutationHookResult = ReturnType<typeof useCreateUserCompletelyWithEstateMutation>;
export type CreateUserCompletelyWithEstateMutationResult = Apollo.MutationResult<CreateUserCompletelyWithEstateMutation>;
export type CreateUserCompletelyWithEstateMutationOptions = Apollo.BaseMutationOptions<CreateUserCompletelyWithEstateMutation, CreateUserCompletelyWithEstateMutationVariables>;
export const CreateUserWithEstateDocument = gql`
    mutation CreateUserWithEstate {
  createUserWithEstate {
    id
    active
    forcePasswordReset
    type
    email
    dateOfBirth
    firstName
    lastName
    address
    city
    county
    state
    country
    phone
    partnerId
    createdAt
    updatedAt
  }
}
    `;
export type CreateUserWithEstateMutationFn = Apollo.MutationFunction<CreateUserWithEstateMutation, CreateUserWithEstateMutationVariables>;

/**
 * __useCreateUserWithEstateMutation__
 *
 * To run a mutation, you first call `useCreateUserWithEstateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserWithEstateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserWithEstateMutation, { data, loading, error }] = useCreateUserWithEstateMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateUserWithEstateMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserWithEstateMutation, CreateUserWithEstateMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<CreateUserWithEstateMutation, CreateUserWithEstateMutationVariables>(CreateUserWithEstateDocument, options);
}
export type CreateUserWithEstateMutationHookResult = ReturnType<typeof useCreateUserWithEstateMutation>;
export type CreateUserWithEstateMutationResult = Apollo.MutationResult<CreateUserWithEstateMutation>;
export type CreateUserWithEstateMutationOptions = Apollo.BaseMutationOptions<CreateUserWithEstateMutation, CreateUserWithEstateMutationVariables>;
export const CustomerAddDeceasedToEstateDocument = gql`
    mutation CustomerAddDeceasedToEstate($data: ValidatedEstateUpdateInput!, $where: EstateWhereUniqueInput) {
  updateUserEstate(data: $data, where: $where) {
    id
    deceased {
      id
      firstName
      lastName
    }
  }
}
    `;
export type CustomerAddDeceasedToEstateMutationFn = Apollo.MutationFunction<CustomerAddDeceasedToEstateMutation, CustomerAddDeceasedToEstateMutationVariables>;

/**
 * __useCustomerAddDeceasedToEstateMutation__
 *
 * To run a mutation, you first call `useCustomerAddDeceasedToEstateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerAddDeceasedToEstateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerAddDeceasedToEstateMutation, { data, loading, error }] = useCustomerAddDeceasedToEstateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCustomerAddDeceasedToEstateMutation(baseOptions?: Apollo.MutationHookOptions<CustomerAddDeceasedToEstateMutation, CustomerAddDeceasedToEstateMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<CustomerAddDeceasedToEstateMutation, CustomerAddDeceasedToEstateMutationVariables>(CustomerAddDeceasedToEstateDocument, options);
}
export type CustomerAddDeceasedToEstateMutationHookResult = ReturnType<typeof useCustomerAddDeceasedToEstateMutation>;
export type CustomerAddDeceasedToEstateMutationResult = Apollo.MutationResult<CustomerAddDeceasedToEstateMutation>;
export type CustomerAddDeceasedToEstateMutationOptions = Apollo.BaseMutationOptions<CustomerAddDeceasedToEstateMutation, CustomerAddDeceasedToEstateMutationVariables>;
export const DeleteDocumentDocument = gql`
    mutation DeleteDocument($id: String!) {
  deleteDocument(id: $id)
}
    `;
export type DeleteDocumentMutationFn = Apollo.MutationFunction<DeleteDocumentMutation, DeleteDocumentMutationVariables>;

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentMutation, { data, loading, error }] = useDeleteDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDocumentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<DeleteDocumentMutation, DeleteDocumentMutationVariables>(DeleteDocumentDocument, options);
}
export type DeleteDocumentMutationHookResult = ReturnType<typeof useDeleteDocumentMutation>;
export type DeleteDocumentMutationResult = Apollo.MutationResult<DeleteDocumentMutation>;
export type DeleteDocumentMutationOptions = Apollo.BaseMutationOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>;
export const DeleteOneActionCardDocument = gql`
    mutation DeleteOneActionCard($where: ActionCardWhereUniqueInput!) {
  deleteOneActionCard(where: $where) {
    id
  }
}
    `;
export type DeleteOneActionCardMutationFn = Apollo.MutationFunction<DeleteOneActionCardMutation, DeleteOneActionCardMutationVariables>;

/**
 * __useDeleteOneActionCardMutation__
 *
 * To run a mutation, you first call `useDeleteOneActionCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneActionCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneActionCardMutation, { data, loading, error }] = useDeleteOneActionCardMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteOneActionCardMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneActionCardMutation, DeleteOneActionCardMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<DeleteOneActionCardMutation, DeleteOneActionCardMutationVariables>(DeleteOneActionCardDocument, options);
}
export type DeleteOneActionCardMutationHookResult = ReturnType<typeof useDeleteOneActionCardMutation>;
export type DeleteOneActionCardMutationResult = Apollo.MutationResult<DeleteOneActionCardMutation>;
export type DeleteOneActionCardMutationOptions = Apollo.BaseMutationOptions<DeleteOneActionCardMutation, DeleteOneActionCardMutationVariables>;
export const DeleteOneComponentDocument = gql`
    mutation DeleteOneComponent($where: ComponentWhereUniqueInput!) {
  deleteOneComponent(where: $where) {
    id
  }
}
    `;
export type DeleteOneComponentMutationFn = Apollo.MutationFunction<DeleteOneComponentMutation, DeleteOneComponentMutationVariables>;

/**
 * __useDeleteOneComponentMutation__
 *
 * To run a mutation, you first call `useDeleteOneComponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneComponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneComponentMutation, { data, loading, error }] = useDeleteOneComponentMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteOneComponentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneComponentMutation, DeleteOneComponentMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<DeleteOneComponentMutation, DeleteOneComponentMutationVariables>(DeleteOneComponentDocument, options);
}
export type DeleteOneComponentMutationHookResult = ReturnType<typeof useDeleteOneComponentMutation>;
export type DeleteOneComponentMutationResult = Apollo.MutationResult<DeleteOneComponentMutation>;
export type DeleteOneComponentMutationOptions = Apollo.BaseMutationOptions<DeleteOneComponentMutation, DeleteOneComponentMutationVariables>;
export const DeleteOneContentDocument = gql`
    mutation DeleteOneContent($where: ContentWhereUniqueInput!) {
  deleteOneContent(where: $where) {
    id
  }
}
    `;
export type DeleteOneContentMutationFn = Apollo.MutationFunction<DeleteOneContentMutation, DeleteOneContentMutationVariables>;

/**
 * __useDeleteOneContentMutation__
 *
 * To run a mutation, you first call `useDeleteOneContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneContentMutation, { data, loading, error }] = useDeleteOneContentMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteOneContentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneContentMutation, DeleteOneContentMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<DeleteOneContentMutation, DeleteOneContentMutationVariables>(DeleteOneContentDocument, options);
}
export type DeleteOneContentMutationHookResult = ReturnType<typeof useDeleteOneContentMutation>;
export type DeleteOneContentMutationResult = Apollo.MutationResult<DeleteOneContentMutation>;
export type DeleteOneContentMutationOptions = Apollo.BaseMutationOptions<DeleteOneContentMutation, DeleteOneContentMutationVariables>;
export const DeleteUserNotificationsDocument = gql`
    mutation DeleteUserNotifications($data: DeleteUserNotificationsInput!) {
  deleteUserNotifications(data: $data) {
    estateId
    estateNotificationId
    message
    title
  }
}
    `;
export type DeleteUserNotificationsMutationFn = Apollo.MutationFunction<DeleteUserNotificationsMutation, DeleteUserNotificationsMutationVariables>;

/**
 * __useDeleteUserNotificationsMutation__
 *
 * To run a mutation, you first call `useDeleteUserNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserNotificationsMutation, { data, loading, error }] = useDeleteUserNotificationsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteUserNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserNotificationsMutation, DeleteUserNotificationsMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<DeleteUserNotificationsMutation, DeleteUserNotificationsMutationVariables>(DeleteUserNotificationsDocument, options);
}
export type DeleteUserNotificationsMutationHookResult = ReturnType<typeof useDeleteUserNotificationsMutation>;
export type DeleteUserNotificationsMutationResult = Apollo.MutationResult<DeleteUserNotificationsMutation>;
export type DeleteUserNotificationsMutationOptions = Apollo.BaseMutationOptions<DeleteUserNotificationsMutation, DeleteUserNotificationsMutationVariables>;
export const MarkNotificationsSeenDocument = gql`
    mutation MarkNotificationsSeen($notificationIds: [String!]!, $userId: String) {
  markNotificationsSeen(notificationIds: $notificationIds, userId: $userId)
}
    `;
export type MarkNotificationsSeenMutationFn = Apollo.MutationFunction<MarkNotificationsSeenMutation, MarkNotificationsSeenMutationVariables>;

/**
 * __useMarkNotificationsSeenMutation__
 *
 * To run a mutation, you first call `useMarkNotificationsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationsSeenMutation, { data, loading, error }] = useMarkNotificationsSeenMutation({
 *   variables: {
 *      notificationIds: // value for 'notificationIds'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useMarkNotificationsSeenMutation(baseOptions?: Apollo.MutationHookOptions<MarkNotificationsSeenMutation, MarkNotificationsSeenMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<MarkNotificationsSeenMutation, MarkNotificationsSeenMutationVariables>(MarkNotificationsSeenDocument, options);
}
export type MarkNotificationsSeenMutationHookResult = ReturnType<typeof useMarkNotificationsSeenMutation>;
export type MarkNotificationsSeenMutationResult = Apollo.MutationResult<MarkNotificationsSeenMutation>;
export type MarkNotificationsSeenMutationOptions = Apollo.BaseMutationOptions<MarkNotificationsSeenMutation, MarkNotificationsSeenMutationVariables>;
export const MoveDocumentsDocument = gql`
    mutation MoveDocuments($data: MoveDocumentsInput!) {
  moveDocuments(data: $data)
}
    `;
export type MoveDocumentsMutationFn = Apollo.MutationFunction<MoveDocumentsMutation, MoveDocumentsMutationVariables>;

/**
 * __useMoveDocumentsMutation__
 *
 * To run a mutation, you first call `useMoveDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveDocumentsMutation, { data, loading, error }] = useMoveDocumentsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMoveDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<MoveDocumentsMutation, MoveDocumentsMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<MoveDocumentsMutation, MoveDocumentsMutationVariables>(MoveDocumentsDocument, options);
}
export type MoveDocumentsMutationHookResult = ReturnType<typeof useMoveDocumentsMutation>;
export type MoveDocumentsMutationResult = Apollo.MutationResult<MoveDocumentsMutation>;
export type MoveDocumentsMutationOptions = Apollo.BaseMutationOptions<MoveDocumentsMutation, MoveDocumentsMutationVariables>;
export const PrefillFormTemplateDocument = gql`
    mutation PrefillFormTemplate($templateId: String!, $estateId: String!, $userId: String!) {
  prefillFormTemplate(
    templateId: $templateId
    estateId: $estateId
    userId: $userId
  ) {
    createdAt
    id
    url
  }
}
    `;
export type PrefillFormTemplateMutationFn = Apollo.MutationFunction<PrefillFormTemplateMutation, PrefillFormTemplateMutationVariables>;

/**
 * __usePrefillFormTemplateMutation__
 *
 * To run a mutation, you first call `usePrefillFormTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrefillFormTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prefillFormTemplateMutation, { data, loading, error }] = usePrefillFormTemplateMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      estateId: // value for 'estateId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function usePrefillFormTemplateMutation(baseOptions?: Apollo.MutationHookOptions<PrefillFormTemplateMutation, PrefillFormTemplateMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<PrefillFormTemplateMutation, PrefillFormTemplateMutationVariables>(PrefillFormTemplateDocument, options);
}
export type PrefillFormTemplateMutationHookResult = ReturnType<typeof usePrefillFormTemplateMutation>;
export type PrefillFormTemplateMutationResult = Apollo.MutationResult<PrefillFormTemplateMutation>;
export type PrefillFormTemplateMutationOptions = Apollo.BaseMutationOptions<PrefillFormTemplateMutation, PrefillFormTemplateMutationVariables>;
export const RenameDocumentDocument = gql`
    mutation RenameDocument($targetKey: String!, $sourceKey: String!, $estateId: String) {
  renameDocument(
    targetKey: $targetKey
    sourceKey: $sourceKey
    estateId: $estateId
  )
}
    `;
export type RenameDocumentMutationFn = Apollo.MutationFunction<RenameDocumentMutation, RenameDocumentMutationVariables>;

/**
 * __useRenameDocumentMutation__
 *
 * To run a mutation, you first call `useRenameDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameDocumentMutation, { data, loading, error }] = useRenameDocumentMutation({
 *   variables: {
 *      targetKey: // value for 'targetKey'
 *      sourceKey: // value for 'sourceKey'
 *      estateId: // value for 'estateId'
 *   },
 * });
 */
export function useRenameDocumentMutation(baseOptions?: Apollo.MutationHookOptions<RenameDocumentMutation, RenameDocumentMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<RenameDocumentMutation, RenameDocumentMutationVariables>(RenameDocumentDocument, options);
}
export type RenameDocumentMutationHookResult = ReturnType<typeof useRenameDocumentMutation>;
export type RenameDocumentMutationResult = Apollo.MutationResult<RenameDocumentMutation>;
export type RenameDocumentMutationOptions = Apollo.BaseMutationOptions<RenameDocumentMutation, RenameDocumentMutationVariables>;
export const RestoreDocumentDocument = gql`
    mutation RestoreDocument($deleteMarkerId: String!, $restoreDocumentId: String!) {
  restoreDocument(deleteMarkerId: $deleteMarkerId, id: $restoreDocumentId)
}
    `;
export type RestoreDocumentMutationFn = Apollo.MutationFunction<RestoreDocumentMutation, RestoreDocumentMutationVariables>;

/**
 * __useRestoreDocumentMutation__
 *
 * To run a mutation, you first call `useRestoreDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreDocumentMutation, { data, loading, error }] = useRestoreDocumentMutation({
 *   variables: {
 *      deleteMarkerId: // value for 'deleteMarkerId'
 *      restoreDocumentId: // value for 'restoreDocumentId'
 *   },
 * });
 */
export function useRestoreDocumentMutation(baseOptions?: Apollo.MutationHookOptions<RestoreDocumentMutation, RestoreDocumentMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<RestoreDocumentMutation, RestoreDocumentMutationVariables>(RestoreDocumentDocument, options);
}
export type RestoreDocumentMutationHookResult = ReturnType<typeof useRestoreDocumentMutation>;
export type RestoreDocumentMutationResult = Apollo.MutationResult<RestoreDocumentMutation>;
export type RestoreDocumentMutationOptions = Apollo.BaseMutationOptions<RestoreDocumentMutation, RestoreDocumentMutationVariables>;
export const SignUpUserDocument = gql`
    mutation SignUpUser($data: SignUpUserInput!) {
  signUpUser(data: $data) {
    id
    email
  }
}
    `;
export type SignUpUserMutationFn = Apollo.MutationFunction<SignUpUserMutation, SignUpUserMutationVariables>;

/**
 * __useSignUpUserMutation__
 *
 * To run a mutation, you first call `useSignUpUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpUserMutation, { data, loading, error }] = useSignUpUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSignUpUserMutation(baseOptions?: Apollo.MutationHookOptions<SignUpUserMutation, SignUpUserMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<SignUpUserMutation, SignUpUserMutationVariables>(SignUpUserDocument, options);
}
export type SignUpUserMutationHookResult = ReturnType<typeof useSignUpUserMutation>;
export type SignUpUserMutationResult = Apollo.MutationResult<SignUpUserMutation>;
export type SignUpUserMutationOptions = Apollo.BaseMutationOptions<SignUpUserMutation, SignUpUserMutationVariables>;
export const StartActionCardDocument = gql`
    mutation StartActionCard($startActionCardId: String!, $estateId: String, $reply: String) {
  startActionCard(id: $startActionCardId, estateId: $estateId, reply: $reply) {
    id
    estateId
    componentId
    estateDetailId
    type
    status
    name
    description
    assignee
    formFieldName
    encryptedReply
    appointmentTime
    appointmentLocation
    calendarIcs
    calendarLink
    documentDownloadUrl
    documentUploadUrl
    documentSignUrl
    mapUrl
    promoted
    createdAt
    updatedAt
    estateDetail {
      encrypted
      value
    }
  }
}
    `;
export type StartActionCardMutationFn = Apollo.MutationFunction<StartActionCardMutation, StartActionCardMutationVariables>;

/**
 * __useStartActionCardMutation__
 *
 * To run a mutation, you first call `useStartActionCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartActionCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startActionCardMutation, { data, loading, error }] = useStartActionCardMutation({
 *   variables: {
 *      startActionCardId: // value for 'startActionCardId'
 *      estateId: // value for 'estateId'
 *      reply: // value for 'reply'
 *   },
 * });
 */
export function useStartActionCardMutation(baseOptions?: Apollo.MutationHookOptions<StartActionCardMutation, StartActionCardMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<StartActionCardMutation, StartActionCardMutationVariables>(StartActionCardDocument, options);
}
export type StartActionCardMutationHookResult = ReturnType<typeof useStartActionCardMutation>;
export type StartActionCardMutationResult = Apollo.MutationResult<StartActionCardMutation>;
export type StartActionCardMutationOptions = Apollo.BaseMutationOptions<StartActionCardMutation, StartActionCardMutationVariables>;
export const TrackClickDocument = gql`
    mutation TrackClick($element: String!, $estateId: String!) {
  trackClick(element: $element, estateId: $estateId)
}
    `;
export type TrackClickMutationFn = Apollo.MutationFunction<TrackClickMutation, TrackClickMutationVariables>;

/**
 * __useTrackClickMutation__
 *
 * To run a mutation, you first call `useTrackClickMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackClickMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackClickMutation, { data, loading, error }] = useTrackClickMutation({
 *   variables: {
 *      element: // value for 'element'
 *      estateId: // value for 'estateId'
 *   },
 * });
 */
export function useTrackClickMutation(baseOptions?: Apollo.MutationHookOptions<TrackClickMutation, TrackClickMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<TrackClickMutation, TrackClickMutationVariables>(TrackClickDocument, options);
}
export type TrackClickMutationHookResult = ReturnType<typeof useTrackClickMutation>;
export type TrackClickMutationResult = Apollo.MutationResult<TrackClickMutation>;
export type TrackClickMutationOptions = Apollo.BaseMutationOptions<TrackClickMutation, TrackClickMutationVariables>;
export const TrackPageViewDocument = gql`
    mutation TrackPageView($page: String!, $estateId: String!) {
  trackPageView(page: $page, estateId: $estateId)
}
    `;
export type TrackPageViewMutationFn = Apollo.MutationFunction<TrackPageViewMutation, TrackPageViewMutationVariables>;

/**
 * __useTrackPageViewMutation__
 *
 * To run a mutation, you first call `useTrackPageViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackPageViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackPageViewMutation, { data, loading, error }] = useTrackPageViewMutation({
 *   variables: {
 *      page: // value for 'page'
 *      estateId: // value for 'estateId'
 *   },
 * });
 */
export function useTrackPageViewMutation(baseOptions?: Apollo.MutationHookOptions<TrackPageViewMutation, TrackPageViewMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<TrackPageViewMutation, TrackPageViewMutationVariables>(TrackPageViewDocument, options);
}
export type TrackPageViewMutationHookResult = ReturnType<typeof useTrackPageViewMutation>;
export type TrackPageViewMutationResult = Apollo.MutationResult<TrackPageViewMutation>;
export type TrackPageViewMutationOptions = Apollo.BaseMutationOptions<TrackPageViewMutation, TrackPageViewMutationVariables>;
export const TrackScrollDocument = gql`
    mutation TrackScroll($name: String!, $status: String, $estateId: String, $elementId: String) {
  trackScroll(
    name: $name
    status: $status
    estateId: $estateId
    elementId: $elementId
  )
}
    `;
export type TrackScrollMutationFn = Apollo.MutationFunction<TrackScrollMutation, TrackScrollMutationVariables>;

/**
 * __useTrackScrollMutation__
 *
 * To run a mutation, you first call `useTrackScrollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackScrollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackScrollMutation, { data, loading, error }] = useTrackScrollMutation({
 *   variables: {
 *      name: // value for 'name'
 *      status: // value for 'status'
 *      estateId: // value for 'estateId'
 *      elementId: // value for 'elementId'
 *   },
 * });
 */
export function useTrackScrollMutation(baseOptions?: Apollo.MutationHookOptions<TrackScrollMutation, TrackScrollMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<TrackScrollMutation, TrackScrollMutationVariables>(TrackScrollDocument, options);
}
export type TrackScrollMutationHookResult = ReturnType<typeof useTrackScrollMutation>;
export type TrackScrollMutationResult = Apollo.MutationResult<TrackScrollMutation>;
export type TrackScrollMutationOptions = Apollo.BaseMutationOptions<TrackScrollMutation, TrackScrollMutationVariables>;
export const TrackSectionOpenedDocument = gql`
    mutation TrackSectionOpened($section: String!, $estateId: String!) {
  trackSectionOpened(section: $section, estateId: $estateId)
}
    `;
export type TrackSectionOpenedMutationFn = Apollo.MutationFunction<TrackSectionOpenedMutation, TrackSectionOpenedMutationVariables>;

/**
 * __useTrackSectionOpenedMutation__
 *
 * To run a mutation, you first call `useTrackSectionOpenedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackSectionOpenedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackSectionOpenedMutation, { data, loading, error }] = useTrackSectionOpenedMutation({
 *   variables: {
 *      section: // value for 'section'
 *      estateId: // value for 'estateId'
 *   },
 * });
 */
export function useTrackSectionOpenedMutation(baseOptions?: Apollo.MutationHookOptions<TrackSectionOpenedMutation, TrackSectionOpenedMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<TrackSectionOpenedMutation, TrackSectionOpenedMutationVariables>(TrackSectionOpenedDocument, options);
}
export type TrackSectionOpenedMutationHookResult = ReturnType<typeof useTrackSectionOpenedMutation>;
export type TrackSectionOpenedMutationResult = Apollo.MutationResult<TrackSectionOpenedMutation>;
export type TrackSectionOpenedMutationOptions = Apollo.BaseMutationOptions<TrackSectionOpenedMutation, TrackSectionOpenedMutationVariables>;
export const UpdateOneActionCardDocument = gql`
    mutation UpdateOneActionCard($data: ActionCardUpdateInput!, $where: ActionCardWhereUniqueInput!) {
  updateOneActionCard(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateOneActionCardMutationFn = Apollo.MutationFunction<UpdateOneActionCardMutation, UpdateOneActionCardMutationVariables>;

/**
 * __useUpdateOneActionCardMutation__
 *
 * To run a mutation, you first call `useUpdateOneActionCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneActionCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneActionCardMutation, { data, loading, error }] = useUpdateOneActionCardMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneActionCardMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneActionCardMutation, UpdateOneActionCardMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<UpdateOneActionCardMutation, UpdateOneActionCardMutationVariables>(UpdateOneActionCardDocument, options);
}
export type UpdateOneActionCardMutationHookResult = ReturnType<typeof useUpdateOneActionCardMutation>;
export type UpdateOneActionCardMutationResult = Apollo.MutationResult<UpdateOneActionCardMutation>;
export type UpdateOneActionCardMutationOptions = Apollo.BaseMutationOptions<UpdateOneActionCardMutation, UpdateOneActionCardMutationVariables>;
export const UpdateOneComponentDocument = gql`
    mutation UpdateOneComponent($data: ComponentUpdateInput!, $where: ComponentWhereUniqueInput!) {
  updateOneComponent(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateOneComponentMutationFn = Apollo.MutationFunction<UpdateOneComponentMutation, UpdateOneComponentMutationVariables>;

/**
 * __useUpdateOneComponentMutation__
 *
 * To run a mutation, you first call `useUpdateOneComponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneComponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneComponentMutation, { data, loading, error }] = useUpdateOneComponentMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneComponentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneComponentMutation, UpdateOneComponentMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<UpdateOneComponentMutation, UpdateOneComponentMutationVariables>(UpdateOneComponentDocument, options);
}
export type UpdateOneComponentMutationHookResult = ReturnType<typeof useUpdateOneComponentMutation>;
export type UpdateOneComponentMutationResult = Apollo.MutationResult<UpdateOneComponentMutation>;
export type UpdateOneComponentMutationOptions = Apollo.BaseMutationOptions<UpdateOneComponentMutation, UpdateOneComponentMutationVariables>;
export const UpdateOneContentDocument = gql`
    mutation UpdateOneContent($data: ContentUpdateInput!, $where: ContentWhereUniqueInput!) {
  updateOneContent(data: $data, where: $where) {
    id
    header
    status
    completeBy
    completedAt
  }
}
    `;
export type UpdateOneContentMutationFn = Apollo.MutationFunction<UpdateOneContentMutation, UpdateOneContentMutationVariables>;

/**
 * __useUpdateOneContentMutation__
 *
 * To run a mutation, you first call `useUpdateOneContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneContentMutation, { data, loading, error }] = useUpdateOneContentMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneContentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneContentMutation, UpdateOneContentMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<UpdateOneContentMutation, UpdateOneContentMutationVariables>(UpdateOneContentDocument, options);
}
export type UpdateOneContentMutationHookResult = ReturnType<typeof useUpdateOneContentMutation>;
export type UpdateOneContentMutationResult = Apollo.MutationResult<UpdateOneContentMutation>;
export type UpdateOneContentMutationOptions = Apollo.BaseMutationOptions<UpdateOneContentMutation, UpdateOneContentMutationVariables>;
export const UpdateOneDeceasedDocument = gql`
    mutation UpdateOneDeceased($data: DeceasedUpdateInput!, $where: DeceasedWhereUniqueInput!) {
  updateOneDeceased(data: $data, where: $where) {
    id
    firstName
    lastName
    podState
    state
    dateOfBirth
    dateOfDeath
  }
}
    `;
export type UpdateOneDeceasedMutationFn = Apollo.MutationFunction<UpdateOneDeceasedMutation, UpdateOneDeceasedMutationVariables>;

/**
 * __useUpdateOneDeceasedMutation__
 *
 * To run a mutation, you first call `useUpdateOneDeceasedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneDeceasedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneDeceasedMutation, { data, loading, error }] = useUpdateOneDeceasedMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneDeceasedMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneDeceasedMutation, UpdateOneDeceasedMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<UpdateOneDeceasedMutation, UpdateOneDeceasedMutationVariables>(UpdateOneDeceasedDocument, options);
}
export type UpdateOneDeceasedMutationHookResult = ReturnType<typeof useUpdateOneDeceasedMutation>;
export type UpdateOneDeceasedMutationResult = Apollo.MutationResult<UpdateOneDeceasedMutation>;
export type UpdateOneDeceasedMutationOptions = Apollo.BaseMutationOptions<UpdateOneDeceasedMutation, UpdateOneDeceasedMutationVariables>;
export const UpdateOneEstateDocument = gql`
    mutation UpdateOneEstate($data: EstateUpdateInput!, $where: EstateWhereUniqueInput!) {
  updateOneEstate(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateOneEstateMutationFn = Apollo.MutationFunction<UpdateOneEstateMutation, UpdateOneEstateMutationVariables>;

/**
 * __useUpdateOneEstateMutation__
 *
 * To run a mutation, you first call `useUpdateOneEstateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneEstateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneEstateMutation, { data, loading, error }] = useUpdateOneEstateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneEstateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneEstateMutation, UpdateOneEstateMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<UpdateOneEstateMutation, UpdateOneEstateMutationVariables>(UpdateOneEstateDocument, options);
}
export type UpdateOneEstateMutationHookResult = ReturnType<typeof useUpdateOneEstateMutation>;
export type UpdateOneEstateMutationResult = Apollo.MutationResult<UpdateOneEstateMutation>;
export type UpdateOneEstateMutationOptions = Apollo.BaseMutationOptions<UpdateOneEstateMutation, UpdateOneEstateMutationVariables>;
export const UpdateOneUserDocument = gql`
    mutation UpdateOneUser($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
  updateOneUser(data: $data, where: $where) {
    id
    firstName
    lastName
    email
  }
}
    `;
export type UpdateOneUserMutationFn = Apollo.MutationFunction<UpdateOneUserMutation, UpdateOneUserMutationVariables>;

/**
 * __useUpdateOneUserMutation__
 *
 * To run a mutation, you first call `useUpdateOneUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneUserMutation, { data, loading, error }] = useUpdateOneUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneUserMutation, UpdateOneUserMutationVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useMutation<UpdateOneUserMutation, UpdateOneUserMutationVariables>(UpdateOneUserDocument, options);
}
export type UpdateOneUserMutationHookResult = ReturnType<typeof useUpdateOneUserMutation>;
export type UpdateOneUserMutationResult = Apollo.MutationResult<UpdateOneUserMutation>;
export type UpdateOneUserMutationOptions = Apollo.BaseMutationOptions<UpdateOneUserMutation, UpdateOneUserMutationVariables>;
export const AdminGetUsersOnEstateDocument = gql`
    query AdminGetUsersOnEstate($where: EstateWhereUniqueInput!, $orderBy: [UsersOnEstatesOrderByWithRelationInput!]) {
  estate(where: $where) {
    users(orderBy: $orderBy) {
      user {
        id
        firstName
        lastName
      }
      userRole
    }
  }
}
    `;

/**
 * __useAdminGetUsersOnEstateQuery__
 *
 * To run a query within a React component, call `useAdminGetUsersOnEstateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetUsersOnEstateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetUsersOnEstateQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useAdminGetUsersOnEstateQuery(baseOptions: Apollo.QueryHookOptions<AdminGetUsersOnEstateQuery, AdminGetUsersOnEstateQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useQuery<AdminGetUsersOnEstateQuery, AdminGetUsersOnEstateQueryVariables>(AdminGetUsersOnEstateDocument, options);
}
export function useAdminGetUsersOnEstateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetUsersOnEstateQuery, AdminGetUsersOnEstateQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useLazyQuery<AdminGetUsersOnEstateQuery, AdminGetUsersOnEstateQueryVariables>(AdminGetUsersOnEstateDocument, options);
}
export type AdminGetUsersOnEstateQueryHookResult = ReturnType<typeof useAdminGetUsersOnEstateQuery>;
export type AdminGetUsersOnEstateLazyQueryHookResult = ReturnType<typeof useAdminGetUsersOnEstateLazyQuery>;
export type AdminGetUsersOnEstateQueryResult = Apollo.QueryResult<AdminGetUsersOnEstateQuery, AdminGetUsersOnEstateQueryVariables>;
export const FormTemplatesDocument = gql`
    query FormTemplates {
  formTemplates {
    id
    title
  }
}
    `;

/**
 * __useFormTemplatesQuery__
 *
 * To run a query within a React component, call `useFormTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFormTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<FormTemplatesQuery, FormTemplatesQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useQuery<FormTemplatesQuery, FormTemplatesQueryVariables>(FormTemplatesDocument, options);
}
export function useFormTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormTemplatesQuery, FormTemplatesQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useLazyQuery<FormTemplatesQuery, FormTemplatesQueryVariables>(FormTemplatesDocument, options);
}
export type FormTemplatesQueryHookResult = ReturnType<typeof useFormTemplatesQuery>;
export type FormTemplatesLazyQueryHookResult = ReturnType<typeof useFormTemplatesLazyQuery>;
export type FormTemplatesQueryResult = Apollo.QueryResult<FormTemplatesQuery, FormTemplatesQueryVariables>;
export const GetActionCardDocument = gql`
    query GetActionCard($where: ActionCardWhereUniqueInput!) {
  actionCard(where: $where) {
    estateDetail {
      value
      id
      decryptedValue
    }
    id
    estateId
    componentId
    estateDetailId
    type
    status
    name
    description
    assignee
    formFieldName
    encryptedReply
    appointmentTime
    appointmentLocation
    calendarIcs
    calendarLink
    documentDownloadUrl
    documentUploadUrl
    documentSignUrl
    mapUrl
    promoted
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetActionCardQuery__
 *
 * To run a query within a React component, call `useGetActionCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActionCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActionCardQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetActionCardQuery(baseOptions: Apollo.QueryHookOptions<GetActionCardQuery, GetActionCardQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useQuery<GetActionCardQuery, GetActionCardQueryVariables>(GetActionCardDocument, options);
}
export function useGetActionCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActionCardQuery, GetActionCardQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useLazyQuery<GetActionCardQuery, GetActionCardQueryVariables>(GetActionCardDocument, options);
}
export type GetActionCardQueryHookResult = ReturnType<typeof useGetActionCardQuery>;
export type GetActionCardLazyQueryHookResult = ReturnType<typeof useGetActionCardLazyQuery>;
export type GetActionCardQueryResult = Apollo.QueryResult<GetActionCardQuery, GetActionCardQueryVariables>;
export const GetCurrentUserDocument = gql`
    query GetCurrentUser {
  getCurrentUser {
    id
    forcePasswordReset
    active
    firstName
    lastName
    email
    avatar
  }
}
    `;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
}
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
}
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetEstateComponentDocument = gql`
    query GetEstateComponent($componentId: String!, $orderBy: [ContentOrderByWithRelationInput!]) {
  getEstateComponent(componentId: $componentId) {
    id
    name
    type
    subType
    descriptor
    contents(orderBy: $orderBy) {
      id
      header
      body
      status
      user {
        firstName
        lastName
        avatar
      }
      displayDate
      completeBy
      completeByType
      completedAt
    }
    actionCards {
      id
      name
      status
      type
    }
    notifications {
      id
      title
      message
    }
  }
}
    `;

/**
 * __useGetEstateComponentQuery__
 *
 * To run a query within a React component, call `useGetEstateComponentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEstateComponentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEstateComponentQuery({
 *   variables: {
 *      componentId: // value for 'componentId'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetEstateComponentQuery(baseOptions: Apollo.QueryHookOptions<GetEstateComponentQuery, GetEstateComponentQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useQuery<GetEstateComponentQuery, GetEstateComponentQueryVariables>(GetEstateComponentDocument, options);
}
export function useGetEstateComponentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEstateComponentQuery, GetEstateComponentQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useLazyQuery<GetEstateComponentQuery, GetEstateComponentQueryVariables>(GetEstateComponentDocument, options);
}
export type GetEstateComponentQueryHookResult = ReturnType<typeof useGetEstateComponentQuery>;
export type GetEstateComponentLazyQueryHookResult = ReturnType<typeof useGetEstateComponentLazyQuery>;
export type GetEstateComponentQueryResult = Apollo.QueryResult<GetEstateComponentQuery, GetEstateComponentQueryVariables>;
export const GetEstateComponentSummaryDocument = gql`
    query GetEstateComponentSummary($componentId: String!) {
  getEstateComponent(componentId: $componentId) {
    id
    accountHolder
    accountNumber
    address
    city
    country
    descriptor
    estimatedValue
    finalValue
    name
    state
    status
    subType
    type
    zip
  }
}
    `;

/**
 * __useGetEstateComponentSummaryQuery__
 *
 * To run a query within a React component, call `useGetEstateComponentSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEstateComponentSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEstateComponentSummaryQuery({
 *   variables: {
 *      componentId: // value for 'componentId'
 *   },
 * });
 */
export function useGetEstateComponentSummaryQuery(baseOptions: Apollo.QueryHookOptions<GetEstateComponentSummaryQuery, GetEstateComponentSummaryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useQuery<GetEstateComponentSummaryQuery, GetEstateComponentSummaryQueryVariables>(GetEstateComponentSummaryDocument, options);
}
export function useGetEstateComponentSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEstateComponentSummaryQuery, GetEstateComponentSummaryQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useLazyQuery<GetEstateComponentSummaryQuery, GetEstateComponentSummaryQueryVariables>(GetEstateComponentSummaryDocument, options);
}
export type GetEstateComponentSummaryQueryHookResult = ReturnType<typeof useGetEstateComponentSummaryQuery>;
export type GetEstateComponentSummaryLazyQueryHookResult = ReturnType<typeof useGetEstateComponentSummaryLazyQuery>;
export type GetEstateComponentSummaryQueryResult = Apollo.QueryResult<GetEstateComponentSummaryQuery, GetEstateComponentSummaryQueryVariables>;
export const GetEstateNotificationsDocument = gql`
    query GetEstateNotifications($estateId: String!) {
  getEstateNotifications(estateId: $estateId) {
    componentId
    createdAt
    estateId
    id
    message
    title
    updatedAt
    userNotifications {
      user {
        firstName
        lastName
        estates {
          userRole
        }
      }
      userId
      seenAt
    }
  }
}
    `;

/**
 * __useGetEstateNotificationsQuery__
 *
 * To run a query within a React component, call `useGetEstateNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEstateNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEstateNotificationsQuery({
 *   variables: {
 *      estateId: // value for 'estateId'
 *   },
 * });
 */
export function useGetEstateNotificationsQuery(baseOptions: Apollo.QueryHookOptions<GetEstateNotificationsQuery, GetEstateNotificationsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useQuery<GetEstateNotificationsQuery, GetEstateNotificationsQueryVariables>(GetEstateNotificationsDocument, options);
}
export function useGetEstateNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEstateNotificationsQuery, GetEstateNotificationsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useLazyQuery<GetEstateNotificationsQuery, GetEstateNotificationsQueryVariables>(GetEstateNotificationsDocument, options);
}
export type GetEstateNotificationsQueryHookResult = ReturnType<typeof useGetEstateNotificationsQuery>;
export type GetEstateNotificationsLazyQueryHookResult = ReturnType<typeof useGetEstateNotificationsLazyQuery>;
export type GetEstateNotificationsQueryResult = Apollo.QueryResult<GetEstateNotificationsQuery, GetEstateNotificationsQueryVariables>;
export const GetEstatePieDataDocument = gql`
    query GetEstatePieData($estateId: String) {
  getUserEstate(estateId: $estateId) {
    id
    componentBreakDown {
      type
      subType
      sum
      percentage
      count
      total
    }
  }
}
    `;

/**
 * __useGetEstatePieDataQuery__
 *
 * To run a query within a React component, call `useGetEstatePieDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEstatePieDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEstatePieDataQuery({
 *   variables: {
 *      estateId: // value for 'estateId'
 *   },
 * });
 */
export function useGetEstatePieDataQuery(baseOptions?: Apollo.QueryHookOptions<GetEstatePieDataQuery, GetEstatePieDataQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useQuery<GetEstatePieDataQuery, GetEstatePieDataQueryVariables>(GetEstatePieDataDocument, options);
}
export function useGetEstatePieDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEstatePieDataQuery, GetEstatePieDataQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useLazyQuery<GetEstatePieDataQuery, GetEstatePieDataQueryVariables>(GetEstatePieDataDocument, options);
}
export type GetEstatePieDataQueryHookResult = ReturnType<typeof useGetEstatePieDataQuery>;
export type GetEstatePieDataLazyQueryHookResult = ReturnType<typeof useGetEstatePieDataLazyQuery>;
export type GetEstatePieDataQueryResult = Apollo.QueryResult<GetEstatePieDataQuery, GetEstatePieDataQueryVariables>;
export const GetKeyDatesDocument = gql`
    query GetKeyDates($estateId: String) {
  getUserEstate(estateId: $estateId) {
    keyDates {
      id
      header
      body
      status
      user {
        firstName
        lastName
        avatar
      }
      displayDate
      componentId
      completeBy
      completeByType
      completedAt
    }
  }
}
    `;

/**
 * __useGetKeyDatesQuery__
 *
 * To run a query within a React component, call `useGetKeyDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKeyDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKeyDatesQuery({
 *   variables: {
 *      estateId: // value for 'estateId'
 *   },
 * });
 */
export function useGetKeyDatesQuery(baseOptions?: Apollo.QueryHookOptions<GetKeyDatesQuery, GetKeyDatesQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useQuery<GetKeyDatesQuery, GetKeyDatesQueryVariables>(GetKeyDatesDocument, options);
}
export function useGetKeyDatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKeyDatesQuery, GetKeyDatesQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useLazyQuery<GetKeyDatesQuery, GetKeyDatesQueryVariables>(GetKeyDatesDocument, options);
}
export type GetKeyDatesQueryHookResult = ReturnType<typeof useGetKeyDatesQuery>;
export type GetKeyDatesLazyQueryHookResult = ReturnType<typeof useGetKeyDatesLazyQuery>;
export type GetKeyDatesQueryResult = Apollo.QueryResult<GetKeyDatesQuery, GetKeyDatesQueryVariables>;
export const GetMostRecentEulaDocument = gql`
    query GetMostRecentEULA {
  getMostRecentEULA {
    id
    version
    pdfLink
  }
}
    `;

/**
 * __useGetMostRecentEulaQuery__
 *
 * To run a query within a React component, call `useGetMostRecentEulaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMostRecentEulaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMostRecentEulaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMostRecentEulaQuery(baseOptions?: Apollo.QueryHookOptions<GetMostRecentEulaQuery, GetMostRecentEulaQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useQuery<GetMostRecentEulaQuery, GetMostRecentEulaQueryVariables>(GetMostRecentEulaDocument, options);
}
export function useGetMostRecentEulaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMostRecentEulaQuery, GetMostRecentEulaQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useLazyQuery<GetMostRecentEulaQuery, GetMostRecentEulaQueryVariables>(GetMostRecentEulaDocument, options);
}
export type GetMostRecentEulaQueryHookResult = ReturnType<typeof useGetMostRecentEulaQuery>;
export type GetMostRecentEulaLazyQueryHookResult = ReturnType<typeof useGetMostRecentEulaLazyQuery>;
export type GetMostRecentEulaQueryResult = Apollo.QueryResult<GetMostRecentEulaQuery, GetMostRecentEulaQueryVariables>;
export const GetNotificationLogDocument = gql`
    query GetNotificationLog($estateId: String!) {
  getNotificationLog(estateId: $estateId) {
    estateId
    id
    title
    message
    notificationReceipts {
      createdAt
      notificationId
      seenAt
      user {
        userId
        firstName
        lastName
      }
    }
    createdAt
    updatedAt
    actionCardId
    componentId
    contentId
    route
    routingError {
      message
    }
  }
}
    `;

/**
 * __useGetNotificationLogQuery__
 *
 * To run a query within a React component, call `useGetNotificationLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationLogQuery({
 *   variables: {
 *      estateId: // value for 'estateId'
 *   },
 * });
 */
export function useGetNotificationLogQuery(baseOptions: Apollo.QueryHookOptions<GetNotificationLogQuery, GetNotificationLogQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useQuery<GetNotificationLogQuery, GetNotificationLogQueryVariables>(GetNotificationLogDocument, options);
}
export function useGetNotificationLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationLogQuery, GetNotificationLogQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useLazyQuery<GetNotificationLogQuery, GetNotificationLogQueryVariables>(GetNotificationLogDocument, options);
}
export type GetNotificationLogQueryHookResult = ReturnType<typeof useGetNotificationLogQuery>;
export type GetNotificationLogLazyQueryHookResult = ReturnType<typeof useGetNotificationLogLazyQuery>;
export type GetNotificationLogQueryResult = Apollo.QueryResult<GetNotificationLogQuery, GetNotificationLogQueryVariables>;
export const GetOneContentDocument = gql`
    query GetOneContent($where: ContentWhereUniqueInput!) {
  getContent(where: $where) {
    id
    completeByType
    completeBy
    displayDate
    body
    componentId
    header
    status
    userId
    type
    user {
      id
      firstName
      lastName
      avatar
    }
  }
}
    `;

/**
 * __useGetOneContentQuery__
 *
 * To run a query within a React component, call `useGetOneContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneContentQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetOneContentQuery(baseOptions: Apollo.QueryHookOptions<GetOneContentQuery, GetOneContentQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useQuery<GetOneContentQuery, GetOneContentQueryVariables>(GetOneContentDocument, options);
}
export function useGetOneContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOneContentQuery, GetOneContentQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useLazyQuery<GetOneContentQuery, GetOneContentQueryVariables>(GetOneContentDocument, options);
}
export type GetOneContentQueryHookResult = ReturnType<typeof useGetOneContentQuery>;
export type GetOneContentLazyQueryHookResult = ReturnType<typeof useGetOneContentLazyQuery>;
export type GetOneContentQueryResult = Apollo.QueryResult<GetOneContentQuery, GetOneContentQueryVariables>;
export const GetRecentActivityDocument = gql`
    query GetRecentActivity($estateId: String) {
  getUserEstate(estateId: $estateId) {
    recentActivity {
      id
      header
      body
      status
      user {
        firstName
        lastName
        avatar
      }
      displayDate
      componentId
      completeBy
      completeByType
      completedAt
      type
    }
  }
}
    `;

/**
 * __useGetRecentActivityQuery__
 *
 * To run a query within a React component, call `useGetRecentActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentActivityQuery({
 *   variables: {
 *      estateId: // value for 'estateId'
 *   },
 * });
 */
export function useGetRecentActivityQuery(baseOptions?: Apollo.QueryHookOptions<GetRecentActivityQuery, GetRecentActivityQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useQuery<GetRecentActivityQuery, GetRecentActivityQueryVariables>(GetRecentActivityDocument, options);
}
export function useGetRecentActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecentActivityQuery, GetRecentActivityQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useLazyQuery<GetRecentActivityQuery, GetRecentActivityQueryVariables>(GetRecentActivityDocument, options);
}
export type GetRecentActivityQueryHookResult = ReturnType<typeof useGetRecentActivityQuery>;
export type GetRecentActivityLazyQueryHookResult = ReturnType<typeof useGetRecentActivityLazyQuery>;
export type GetRecentActivityQueryResult = Apollo.QueryResult<GetRecentActivityQuery, GetRecentActivityQueryVariables>;
export const GetSimpleEstateListDocument = gql`
    query GetSimpleEstateList($where: UsersOnEstatesWhereInput) {
  estates {
    id
    deceased {
      id
      firstName
      lastName
    }
    users(where: $where) {
      user {
        id
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useGetSimpleEstateListQuery__
 *
 * To run a query within a React component, call `useGetSimpleEstateListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSimpleEstateListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimpleEstateListQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetSimpleEstateListQuery(baseOptions?: Apollo.QueryHookOptions<GetSimpleEstateListQuery, GetSimpleEstateListQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useQuery<GetSimpleEstateListQuery, GetSimpleEstateListQueryVariables>(GetSimpleEstateListDocument, options);
}
export function useGetSimpleEstateListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSimpleEstateListQuery, GetSimpleEstateListQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useLazyQuery<GetSimpleEstateListQuery, GetSimpleEstateListQueryVariables>(GetSimpleEstateListDocument, options);
}
export type GetSimpleEstateListQueryHookResult = ReturnType<typeof useGetSimpleEstateListQuery>;
export type GetSimpleEstateListLazyQueryHookResult = ReturnType<typeof useGetSimpleEstateListLazyQuery>;
export type GetSimpleEstateListQueryResult = Apollo.QueryResult<GetSimpleEstateListQuery, GetSimpleEstateListQueryVariables>;
export const GetUnseenNotificationsCountDocument = gql`
    query GetUnseenNotificationsCount($estateId: String, $userId: String) {
  getUnseenNotificationsCount(estateId: $estateId, userId: $userId)
}
    `;

/**
 * __useGetUnseenNotificationsCountQuery__
 *
 * To run a query within a React component, call `useGetUnseenNotificationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnseenNotificationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnseenNotificationsCountQuery({
 *   variables: {
 *      estateId: // value for 'estateId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUnseenNotificationsCountQuery(baseOptions?: Apollo.QueryHookOptions<GetUnseenNotificationsCountQuery, GetUnseenNotificationsCountQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useQuery<GetUnseenNotificationsCountQuery, GetUnseenNotificationsCountQueryVariables>(GetUnseenNotificationsCountDocument, options);
}
export function useGetUnseenNotificationsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnseenNotificationsCountQuery, GetUnseenNotificationsCountQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useLazyQuery<GetUnseenNotificationsCountQuery, GetUnseenNotificationsCountQueryVariables>(GetUnseenNotificationsCountDocument, options);
}
export type GetUnseenNotificationsCountQueryHookResult = ReturnType<typeof useGetUnseenNotificationsCountQuery>;
export type GetUnseenNotificationsCountLazyQueryHookResult = ReturnType<typeof useGetUnseenNotificationsCountLazyQuery>;
export type GetUnseenNotificationsCountQueryResult = Apollo.QueryResult<GetUnseenNotificationsCountQuery, GetUnseenNotificationsCountQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($where: UserWhereUniqueInput!) {
  getUser(where: $where) {
    id
    active
    type
    email
    dateOfBirth
    firstName
    lastName
    address
    city
    county
    state
    country
    phone
    partnerId
    ssnLast4
    fullSsn
    createdAt
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUserActionCardDocument = gql`
    query GetUserActionCard($where: ActionCardWhereInput, $estateId: String) {
  getUserEstate(estateId: $estateId) {
    actionCards(where: $where) {
      id
      estateId
      componentId
      completedAt
      estateDetailId
      taskId
      type
      status
      name
      description
      encryptedReply
      locked
      assignee
      formFieldName
      appointmentTime
      appointmentLocation
      calendarIcs
      calendarLink
      documentDownloadUrl
      documentUploadUrl
      documentSignUrl
      mapUrl
      createdAt
      updatedAt
      component {
        id
        name
        subType
        type
        accountNumber
        descriptor
      }
      task {
        name
        shortName
      }
      estateDetail {
        id
        name
        updatedAt
        value
      }
    }
  }
}
    `;

/**
 * __useGetUserActionCardQuery__
 *
 * To run a query within a React component, call `useGetUserActionCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserActionCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserActionCardQuery({
 *   variables: {
 *      where: // value for 'where'
 *      estateId: // value for 'estateId'
 *   },
 * });
 */
export function useGetUserActionCardQuery(baseOptions?: Apollo.QueryHookOptions<GetUserActionCardQuery, GetUserActionCardQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useQuery<GetUserActionCardQuery, GetUserActionCardQueryVariables>(GetUserActionCardDocument, options);
}
export function useGetUserActionCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserActionCardQuery, GetUserActionCardQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useLazyQuery<GetUserActionCardQuery, GetUserActionCardQueryVariables>(GetUserActionCardDocument, options);
}
export type GetUserActionCardQueryHookResult = ReturnType<typeof useGetUserActionCardQuery>;
export type GetUserActionCardLazyQueryHookResult = ReturnType<typeof useGetUserActionCardLazyQuery>;
export type GetUserActionCardQueryResult = Apollo.QueryResult<GetUserActionCardQuery, GetUserActionCardQueryVariables>;
export const GetUserEstateActionCardsDocument = gql`
    query GetUserEstateActionCards($estateId: String, $where: ActionCardWhereInput, $orderBy: [ActionCardOrderByWithRelationInput!]) {
  getUserEstate(estateId: $estateId) {
    id
    deceased {
      id
      firstName
      lastName
      podState
      state
      dateOfBirth
      dateOfDeath
    }
    componentBreakDown {
      type
      subType
      sum
      percentage
      count
      total
    }
    actionCards(where: $where, orderBy: $orderBy) {
      id
      estateId
      componentId
      completedAt
      estateDetailId
      type
      status
      name
      description
      encryptedReply
      locked
      assignee
      formFieldName
      appointmentTime
      appointmentLocation
      calendarIcs
      calendarLink
      documentDownloadUrl
      documentUploadUrl
      documentSignUrl
      mapUrl
      createdAt
      updatedAt
      component {
        id
        name
        subType
        type
        accountNumber
        descriptor
      }
      task {
        name
        shortName
      }
      estateDetail {
        id
        name
        updatedAt
        value
      }
    }
  }
}
    `;

/**
 * __useGetUserEstateActionCardsQuery__
 *
 * To run a query within a React component, call `useGetUserEstateActionCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEstateActionCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEstateActionCardsQuery({
 *   variables: {
 *      estateId: // value for 'estateId'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetUserEstateActionCardsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserEstateActionCardsQuery, GetUserEstateActionCardsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useQuery<GetUserEstateActionCardsQuery, GetUserEstateActionCardsQueryVariables>(GetUserEstateActionCardsDocument, options);
}
export function useGetUserEstateActionCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserEstateActionCardsQuery, GetUserEstateActionCardsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useLazyQuery<GetUserEstateActionCardsQuery, GetUserEstateActionCardsQueryVariables>(GetUserEstateActionCardsDocument, options);
}
export type GetUserEstateActionCardsQueryHookResult = ReturnType<typeof useGetUserEstateActionCardsQuery>;
export type GetUserEstateActionCardsLazyQueryHookResult = ReturnType<typeof useGetUserEstateActionCardsLazyQuery>;
export type GetUserEstateActionCardsQueryResult = Apollo.QueryResult<GetUserEstateActionCardsQuery, GetUserEstateActionCardsQueryVariables>;
export const GetUserEstateIdDocument = gql`
    query GetUserEstateId($estateId: String) {
  getUserEstate(estateId: $estateId) {
    id
  }
}
    `;

/**
 * __useGetUserEstateIdQuery__
 *
 * To run a query within a React component, call `useGetUserEstateIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEstateIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEstateIdQuery({
 *   variables: {
 *      estateId: // value for 'estateId'
 *   },
 * });
 */
export function useGetUserEstateIdQuery(baseOptions?: Apollo.QueryHookOptions<GetUserEstateIdQuery, GetUserEstateIdQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useQuery<GetUserEstateIdQuery, GetUserEstateIdQueryVariables>(GetUserEstateIdDocument, options);
}
export function useGetUserEstateIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserEstateIdQuery, GetUserEstateIdQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useLazyQuery<GetUserEstateIdQuery, GetUserEstateIdQueryVariables>(GetUserEstateIdDocument, options);
}
export type GetUserEstateIdQueryHookResult = ReturnType<typeof useGetUserEstateIdQuery>;
export type GetUserEstateIdLazyQueryHookResult = ReturnType<typeof useGetUserEstateIdLazyQuery>;
export type GetUserEstateIdQueryResult = Apollo.QueryResult<GetUserEstateIdQuery, GetUserEstateIdQueryVariables>;
export const GetUserEstateNotificationsDocument = gql`
    query GetUserEstateNotifications($userId: String, $estateId: String) {
  getUserEstateNotifications(userId: $userId, estateId: $estateId) {
    createdAt
    estateId
    id
    message
    route
    routingError {
      message
    }
    seenAt
    title
    updatedAt
    userId
  }
}
    `;

/**
 * __useGetUserEstateNotificationsQuery__
 *
 * To run a query within a React component, call `useGetUserEstateNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEstateNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEstateNotificationsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      estateId: // value for 'estateId'
 *   },
 * });
 */
export function useGetUserEstateNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserEstateNotificationsQuery, GetUserEstateNotificationsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useQuery<GetUserEstateNotificationsQuery, GetUserEstateNotificationsQueryVariables>(GetUserEstateNotificationsDocument, options);
}
export function useGetUserEstateNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserEstateNotificationsQuery, GetUserEstateNotificationsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useLazyQuery<GetUserEstateNotificationsQuery, GetUserEstateNotificationsQueryVariables>(GetUserEstateNotificationsDocument, options);
}
export type GetUserEstateNotificationsQueryHookResult = ReturnType<typeof useGetUserEstateNotificationsQuery>;
export type GetUserEstateNotificationsLazyQueryHookResult = ReturnType<typeof useGetUserEstateNotificationsLazyQuery>;
export type GetUserEstateNotificationsQueryResult = Apollo.QueryResult<GetUserEstateNotificationsQuery, GetUserEstateNotificationsQueryVariables>;
export const GetUserEstateDocument = gql`
    query GetUserEstate($estateId: String, $where: UsersOnEstatesWhereInput) {
  getUserEstate(estateId: $estateId) {
    id
    deceased {
      id
      firstName
      lastName
      podState
      state
      dateOfBirth
      dateOfDeath
    }
    hasProbate
    hasTrust
    hasWill
    estateComponents {
      id
      estateId
      type
      subType
      name
      descriptor
      finalValue
      estimatedValue
      appraisedValue
      loanAmount
      mortgageBank
      accountNumber
      accountHolder
      address
      city
      state
      zip
      country
      contactName
      contactType
      phone
      email
      balance
      lastCheckDate
      inTrust
      createdById
      status
      completedAt
      registrationExpiresAt
      paidOff
      lastPaymentAmount
      lastPaymentDate
      billingPeriod
      charge
    }
    componentBreakDown {
      type
      subType
      sum
      percentage
      count
      total
    }
    users(where: $where) {
      user {
        firstName
        lastName
        id
      }
    }
  }
}
    `;

/**
 * __useGetUserEstateQuery__
 *
 * To run a query within a React component, call `useGetUserEstateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEstateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEstateQuery({
 *   variables: {
 *      estateId: // value for 'estateId'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUserEstateQuery(baseOptions?: Apollo.QueryHookOptions<GetUserEstateQuery, GetUserEstateQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useQuery<GetUserEstateQuery, GetUserEstateQueryVariables>(GetUserEstateDocument, options);
}
export function useGetUserEstateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserEstateQuery, GetUserEstateQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useLazyQuery<GetUserEstateQuery, GetUserEstateQueryVariables>(GetUserEstateDocument, options);
}
export type GetUserEstateQueryHookResult = ReturnType<typeof useGetUserEstateQuery>;
export type GetUserEstateLazyQueryHookResult = ReturnType<typeof useGetUserEstateLazyQuery>;
export type GetUserEstateQueryResult = Apollo.QueryResult<GetUserEstateQuery, GetUserEstateQueryVariables>;
export const GetUsersListDocument = gql`
    query GetUsersList($orderBy: [UserOrderByWithRelationInput!], $where: UserWhereInput) {
  users(orderBy: $orderBy, where: $where) {
    id
    firstName
    lastName
    email
    address
    avatar
  }
}
    `;

/**
 * __useGetUsersListQuery__
 *
 * To run a query within a React component, call `useGetUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersListQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUsersListQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersListQuery, GetUsersListQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useQuery<GetUsersListQuery, GetUsersListQueryVariables>(GetUsersListDocument, options);
}
export function useGetUsersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersListQuery, GetUsersListQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useLazyQuery<GetUsersListQuery, GetUsersListQueryVariables>(GetUsersListDocument, options);
}
export type GetUsersListQueryHookResult = ReturnType<typeof useGetUsersListQuery>;
export type GetUsersListLazyQueryHookResult = ReturnType<typeof useGetUsersListLazyQuery>;
export type GetUsersListQueryResult = Apollo.QueryResult<GetUsersListQuery, GetUsersListQueryVariables>;
export const IsEulaCurrentDocument = gql`
    query IsEULACurrent {
  isEULAcurrent
}
    `;

/**
 * __useIsEulaCurrentQuery__
 *
 * To run a query within a React component, call `useIsEulaCurrentQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsEulaCurrentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsEulaCurrentQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsEulaCurrentQuery(baseOptions?: Apollo.QueryHookOptions<IsEulaCurrentQuery, IsEulaCurrentQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useQuery<IsEulaCurrentQuery, IsEulaCurrentQueryVariables>(IsEulaCurrentDocument, options);
}
export function useIsEulaCurrentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsEulaCurrentQuery, IsEulaCurrentQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useLazyQuery<IsEulaCurrentQuery, IsEulaCurrentQueryVariables>(IsEulaCurrentDocument, options);
}
export type IsEulaCurrentQueryHookResult = ReturnType<typeof useIsEulaCurrentQuery>;
export type IsEulaCurrentLazyQueryHookResult = ReturnType<typeof useIsEulaCurrentLazyQuery>;
export type IsEulaCurrentQueryResult = Apollo.QueryResult<IsEulaCurrentQuery, IsEulaCurrentQueryVariables>;
export const ListDeletedDocumentsDocument = gql`
    query ListDeletedDocuments($estateId: String) {
  listDeletedDocuments(estateId: $estateId) {
    id
    isDir
    modDate
    name
    signedUrl
    deleteMarkerId
  }
}
    `;

/**
 * __useListDeletedDocumentsQuery__
 *
 * To run a query within a React component, call `useListDeletedDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDeletedDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDeletedDocumentsQuery({
 *   variables: {
 *      estateId: // value for 'estateId'
 *   },
 * });
 */
export function useListDeletedDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<ListDeletedDocumentsQuery, ListDeletedDocumentsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useQuery<ListDeletedDocumentsQuery, ListDeletedDocumentsQueryVariables>(ListDeletedDocumentsDocument, options);
}
export function useListDeletedDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListDeletedDocumentsQuery, ListDeletedDocumentsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useLazyQuery<ListDeletedDocumentsQuery, ListDeletedDocumentsQueryVariables>(ListDeletedDocumentsDocument, options);
}
export type ListDeletedDocumentsQueryHookResult = ReturnType<typeof useListDeletedDocumentsQuery>;
export type ListDeletedDocumentsLazyQueryHookResult = ReturnType<typeof useListDeletedDocumentsLazyQuery>;
export type ListDeletedDocumentsQueryResult = Apollo.QueryResult<ListDeletedDocumentsQuery, ListDeletedDocumentsQueryVariables>;
export const ListDocumentsDocument = gql`
    query ListDocuments($estateId: String, $directory: String) {
  listDocuments(estateId: $estateId, directory: $directory) {
    id
    isDir
    modDate
    name
    size
    signedUrl
    uploadedAt
    contentType
  }
}
    `;

/**
 * __useListDocumentsQuery__
 *
 * To run a query within a React component, call `useListDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDocumentsQuery({
 *   variables: {
 *      estateId: // value for 'estateId'
 *      directory: // value for 'directory'
 *   },
 * });
 */
export function useListDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<ListDocumentsQuery, ListDocumentsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useQuery<ListDocumentsQuery, ListDocumentsQueryVariables>(ListDocumentsDocument, options);
}
export function useListDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListDocumentsQuery, ListDocumentsQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useLazyQuery<ListDocumentsQuery, ListDocumentsQueryVariables>(ListDocumentsDocument, options);
}
export type ListDocumentsQueryHookResult = ReturnType<typeof useListDocumentsQuery>;
export type ListDocumentsLazyQueryHookResult = ReturnType<typeof useListDocumentsLazyQuery>;
export type ListDocumentsQueryResult = Apollo.QueryResult<ListDocumentsQuery, ListDocumentsQueryVariables>;
export const GetEstateDistributionBreakdownDocument = gql`
    query getEstateDistributionBreakdown($estateId: String!) {
  estateDistributionBreakdown(estateId: $estateId) {
    feeCalculationInfo {
      message
      title
    }
    distributionInfo {
      message
      title
    }
    alixFee {
      amount
      status
      updatedAt
    }
    debts
    totalValue
    estimatedDistribution
  }
}
    `;

/**
 * __useGetEstateDistributionBreakdownQuery__
 *
 * To run a query within a React component, call `useGetEstateDistributionBreakdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEstateDistributionBreakdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEstateDistributionBreakdownQuery({
 *   variables: {
 *      estateId: // value for 'estateId'
 *   },
 * });
 */
export function useGetEstateDistributionBreakdownQuery(baseOptions: Apollo.QueryHookOptions<GetEstateDistributionBreakdownQuery, GetEstateDistributionBreakdownQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useQuery<GetEstateDistributionBreakdownQuery, GetEstateDistributionBreakdownQueryVariables>(GetEstateDistributionBreakdownDocument, options);
}
export function useGetEstateDistributionBreakdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEstateDistributionBreakdownQuery, GetEstateDistributionBreakdownQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions,
  }
  return Apollo.useLazyQuery<GetEstateDistributionBreakdownQuery, GetEstateDistributionBreakdownQueryVariables>(GetEstateDistributionBreakdownDocument, options);
}
export type GetEstateDistributionBreakdownQueryHookResult = ReturnType<typeof useGetEstateDistributionBreakdownQuery>;
export type GetEstateDistributionBreakdownLazyQueryHookResult = ReturnType<typeof useGetEstateDistributionBreakdownLazyQuery>;
export type GetEstateDistributionBreakdownQueryResult = Apollo.QueryResult<GetEstateDistributionBreakdownQuery, GetEstateDistributionBreakdownQueryVariables>;